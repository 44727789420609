import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import ExpenseTabs from "./expenses/tabs";
import ExpenseFilters from "./expenses/filters";
import ExpenseBlk from "./expenses/blk";

import {
  fetchExpenses,
  deleteExpense,
  getFilteredExpenses,
} from "../../../states/actions/fetchSingleExpenses";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function Expenses() {
  const [expenseType, setExpenseType] = useState("active");
  useDocumentTitle("Expenses - Loftus");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleExpenses.content);
  const isLoading = useSelector((state) => state.fetchSingleExpenses.isLoading);
  const isItemDeleted = useSelector(
    (state) => state.fetchSingleExpenses.isItemDeleted
  );
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleExpenses.isFilterLoading
  );
  useEffect(() => {
    dispatch(fetchExpenses());
  }, []);
  useEffect(() => {
    if (data?.active_expenses?.length > 0) {
      setExpenseType("active");
    } else if (data?.pending_expenses?.length > 0) {
      setExpenseType("pending");
    } else if (data?.expired_expenses?.length > 0) {
      setExpenseType("expired");
    }
  }, [data]);

  const handleDelete = (e, expense_id) => {
    e.preventDefault();
    dispatch(deleteExpense(expense_id));
  };
  const [filterData, setFilterData] = useState({
    branch_id: null,
  });
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
    };
    dispatch(getFilteredExpenses(formData));
  };
  if (isLoading) return <LoadingScreen />;
  const pending_expenses = data?.pending_expenses;
  const active_expenses = data?.active_expenses;
  const expired_expenses = data?.expired_expenses;
  const branches = data?.branches;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="expenses" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <Breadcrumb page_name="Expenses" add_link="/add-expenses" />
                ) : (
                  <Breadcrumb page_name="Expenses" />
                )}
                <div className="page_body">
                  <ExpenseTabs
                    setExpenseType={setExpenseType}
                    expenseType={expenseType}
                    active_count={active_expenses?.length}
                    pending_count={pending_expenses?.length}
                    expired_count={expired_expenses?.length}
                    branches={branches}
                    handleBranchChange={handleBranchChange}
                  />
                  <div className="tab-content invoice_toggle_outer expenses_toggle">
                    <div
                      id="active"
                      className="tab-pane fade a active in relative"
                    >
                      {isItemDeleted || isFilterLoading ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}

                      {expenseType === "active" ? (
                        <ExpenseBlk
                          items={active_expenses}
                          expense_type="active"
                          deleteExpense={handleDelete}
                        />
                      ) : expenseType === "pending" ? (
                        <ExpenseBlk
                          items={pending_expenses}
                          deleteExpense={handleDelete}
                          expense_type="pending"
                        />
                      ) : expenseType === "expired" ? (
                        <ExpenseBlk
                          items={expired_expenses}
                          expense_type="expired"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
