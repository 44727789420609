import React from "react";
import {
  convertToSlashesDate,
  format_amount,
  format_date_simple,
} from "../../../../helpers/api";

export default function LeaseDetailsSection({ lease, api_data = false }) {
  console.log(lease, "lease");
  return (
    <>
      <ul className="flex offer_cstm_ul">
        <li>
          <strong>Lease Template</strong>
          <em>Standard</em>
        </li>
        <li>
          <strong>Lease Start Date</strong>
          <em>
            {api_data
              ? lease?.format_start_date
              : convertToSlashesDate(lease?.start_date)}
          </em>
        </li>
        <li>
          <strong>Lease End Date</strong>
          <em>
            {api_data
              ? lease?.format_end_date
              : convertToSlashesDate(lease?.end_date)}
          </em>
        </li>
        <li>
          <strong>Continue Month-to-Month</strong>
          <em>{lease?.mnth_mnth === true ? "Yes" : "No"}</em>
        </li>
        <li>
          <strong>Rent Amount</strong>
          <em>
            {(lease?.promo_code === true || lease?.promo_code === "yes") &&
            (lease?.rent_discount || lease?.prev_rent_amount) ? (
              <>
                {parseFloat(lease?.prev_rent_amount) > 0 ? (
                  <>
                    <del>{format_amount(lease?.prev_rent_amount)} </del>
                    {format_amount(lease?.rent_amount)}
                  </>
                ) : (
                  <>
                    <del>{format_amount(lease?.rent_amount)} </del>
                    {format_amount(lease?.rent_discount)}
                  </>
                )}
              </>
            ) : (
              format_amount(lease?.rent_amount)
            )}
          </em>
        </li>
        <li>
          <strong>Security Deposit</strong>
          <em>{format_amount(lease?.security_deposit)}</em>
        </li>

        {/* <li>
                <strong>Rent Due Date</strong>
                <em>{format_date_simple(lease?.rent_due_date)}</em>
            </li> */}
        <li>
          <strong>Rent Grace Period</strong>
          <em>5 days</em>
        </li>
        {/* <li>
                <strong>Late Fee Type</strong>
                <em>{lease?.late_fee_type}</em>
            </li> */}
        <li>
          <strong>Late Fee</strong>
          <em>{format_amount(lease?.late_fee)}</em>
        </li>

        <li>
          <strong>Non-Sufficient Funds Fee</strong>
          <em>{format_amount(lease?.non_sufficient_funds)}</em>
        </li>
      </ul>
      <div className="gap_line_divide"></div>
    </>
  );
}
