import React from "react";
import Text from "../../../common/Text";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function ListingLst({ item, deleteItem, listingType }) {
  return (
    <>
      <div className="lst">
        <ul>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Branch</h5>
            <div>{item.branch}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Address</h5>
            <div dangerouslySetInnerHTML={{ __html: item.address }} />
            <Text
              string={item.mobile_status}
              parse={true}
              className="show_lbl_com_cell"
            />
          </li>
          <li>
            <h5 className="lease_heading_mini">Pricing</h5>
            <div>{item.price}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Available</h5>
            <div>{item.available}</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">On-Market</h5>
            <div>
              {item?.total_days > 0
                ? item?.total_days +
                  " Day" +
                  (parseInt(item?.total_days) > 1 ? "s" : "")
                : "Today"}
            </div>
          </li>
          {/* <li className="hide_cell_hyp">
                        <h5 className="lease_heading_mini">Status</h5>
                        <Text string={item.status} />
                    </li> */}
          {listingType === "active" && mem_permissions === "admin" ? (
            <li className="bTn action_drop_lease">
              <div className="action_drop _dropDown">
                <div className="_dropBtn action_dots">
                  <img src="/images/dashboard/dots.png" alt="" />
                </div>
                <ul className="_dropCnt dropLst">
                  <li>
                    <NavLink to={"/commercial-property/" + item.slug}>
                      View
                    </NavLink>
                  </li>
                  {/* <li>
                                            <NavLink to={"/listing-details/" + item.id}>View</NavLink>
                                        </li> */}

                  <li>
                    <NavLink to={"/edit-listing/" + item.id}>Edit</NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="#!"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          deleteItem(item.id);
                      }}
                      onclick="return confirm('Are you sure?');"
                      className=""
                    >
                      Delete
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
        {listingType === "active" ? (
          <NavLink
            to={"/commercial-property/" + item.slug}
            className="listing_link"
          ></NavLink>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
