import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { postData } from "../../../../helpers/api";
import toast from "react-hot-toast";
import {
  saveCard,
  cardSavedStatusChange,
} from "../../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
export default function AddNewCreditCard({ setAddNewCreditCard }) {
  const dispatch = useDispatch();
  const isAddNewCardProcessing = useSelector(
    (state) => state.payments.isAddNewCardProcessing
  );
  const isCardSaved = useSelector((state) => state.payments.isCardSaved);
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const [isProcessing, setProcessingTo] = useState(false);
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  useEffect(() => {
    if (isCardSaved) {
      setAddNewCreditCard(false);
      dispatch(cardSavedStatusChange());
    }
  }, [isCardSaved]);

  const watchAllFields = watch();
  const onSubmit = async (frmData) => {
    setProcessingTo(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      } else {
        var form_data = new FormData();
        for (var key in frmData) {
          form_data.append(key, watchAllFields[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("card_holder_name", frmData?.card_holder_name);
        await postData("api/create-lease-payment-intent", form_data).then(
          (data) => {
            let client_secret_setup = data.arr.setup_client_secret;
            if (data.status === 1) {
              let card_result = stripe.confirmCardSetup(client_secret_setup, {
                payment_method: {
                  card: cardElement,
                  // billing_details: billingDetails,
                },
              });

              if (card_result.error) {
                setCheckoutError(card_result.error.message);
                setProcessingTo(false);
                return;
              } else {
                let paymentMethod = "";
                let setup_intent_id = "";
                card_result.then((response) => {
                  if (response.error) {
                    setCheckoutError(response.error.message);
                    setProcessingTo(false);
                    return;
                  } else {
                    paymentMethod = response.setupIntent.payment_method;
                    setup_intent_id = response.setupIntent.id;
                    chargePayment(
                      paymentMethodReq,
                      setup_intent_id,
                      paymentMethod,
                      data.arr.customer
                    );
                  }
                });
              }
            } else {
              toast.error(data?.msg);
            }
          }
        );
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
  };
  const chargePayment = async (
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    let data = watchAllFields;

    let newData = {
      ...data,
      payment_method_id: paymentMethodReq.paymentMethod.id,
      customer_id: customer_id,
      card_number: paymentMethodReq.paymentMethod.card.last4,
      card_brand: paymentMethodReq.paymentMethod.card.brand,
      card_exp_month: paymentMethodReq.paymentMethod.card.exp_month,
      card_exp_year: paymentMethodReq.paymentMethod.card.exp_year,
      setup_id: setup_id,
      payment_method: "credit-card",
    };

    dispatch(saveCard(newData));
    // setAddNewCreditCard(false)
    setProcessingTo(false);
  };
  return (
    <section className="popup small_popup" data-popup="create-new-listing">
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <ToastContainer />
            <div
              className="crosBtn"
              onClick={() => setAddNewCreditCard(false)}
            ></div>
            <h4>Add New Credit Card:</h4>
            <div className="row formRow">
              <div className="col-md-12">
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div class="flex flex-2 cardSec">
                    <div class="inner">
                      <input
                        type="text"
                        name=""
                        placeholder="Card Holder Name"
                        class="txtBox"
                        {...register("card_holder_name", {
                          pattern: {
                            value: /^[a-zA-Z][a-zA-Z ]*$/,
                            message: "Invalid value",
                          },
                          required: "Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="card_holder_name"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>

                    <div class="inner">
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "14px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                        onChange={handleCardDetailsChange}
                      />
                      {checkoutError && (
                        <div className="alert alert-danger">
                          {checkoutError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="bTn  text-center">
                    <button
                      type="submit"
                      class="webBtn colorBtn"
                      disabled={
                        isProcessing || isAddNewCardProcessing ? "disabled" : ""
                      }
                    >
                      Save{" "}
                      {isProcessing || isAddNewCardProcessing ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
