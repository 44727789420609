import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import http from "../../../../helpers/http";
import toast, { Toaster } from "react-hot-toast";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";
export default function PayLandlordForm({
  maintenance_row,
  setPayLandlord,
  bank_accounts,
  is_landlord_staff = false,
}) {
  console.log(is_landlord_staff, maintenance_row);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const watchAllFields = watch();
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const onSubmit = async (data) => {
    data = { ...data, m_p_id: maintenance_row?.id };

    try {
      setIsFormProcessing(true);
      const response = await http.post(
        `pay-for-maintenance-request/${maintenance_row?.id}`,
        helpers.doObjToFormData(data)
      );

      setIsFormProcessing(false);
      if (response?.data?.status) {
        toast?.success(
          response?.data?.msg ? response?.data?.msg : "Paid successfully!"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response?.data?.msg);
      }
    } catch (error) {
      helpers.consoleLog(error);
      return [];
    }
  };
  return (
    <>
      <section className="popup small_popup" data-popup="staff">
        <Toaster />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div
                className="crosBtn"
                onClick={() => setPayLandlord(null)}
              ></div>
              <h4>Pay Now</h4>
              <p>Please select a payment method to process the transaction.</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="txtGrp flex ratings_wrap">
                  <select
                    className="txtBox"
                    {...register("ach_account", {
                      required: "Required",
                    })}
                  >
                    {bank_accounts?.length > 0 ? (
                      <>
                        <option value={""}>Select Bank Account</option>
                        {bank_accounts?.map((mem_payment_method_ach, index) => {
                          if (index == 0) {
                            return (
                              <option
                                value={mem_payment_method_ach?.encoded_id}
                                key={index}
                                selected
                              >
                                ************
                                {mem_payment_method_ach?.card_number}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={mem_payment_method_ach?.encoded_id}
                                key={index}
                              >
                                ************
                                {mem_payment_method_ach?.card_number}
                              </option>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <option value="" selected>
                        No account found!
                      </option>
                    )}
                  </select>
                  <ErrorMessage
                    errors={errors}
                    name="ach_account"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp">
                  <h5>
                    Total:{" "}
                    <strong>
                      {helpers.format_amount(
                        parseFloat(maintenance_row?.total_cost) +
                          parseFloat(maintenance_row?.processing_fee)
                      )}
                    </strong>
                  </h5>
                </div>
                <div className="text-center bTn">
                  <button
                    type="button"
                    className="webBtn"
                    onClick={() => setPayLandlord(null)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="webBtn colorBtn"
                    disabled={isFormProcessing}
                  >
                    Submit {isFormProcessing ? <i className="spinner"></i> : ""}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
