import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { format_amount, moment_new_date } from "../../../../helpers/api";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import DigitalTimePicker from "./digital-timepicker";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import { ToastContainer } from "react-toastify";

export default function RentalListingStep6({
  step,
  changeStep,
  register,
  errors,
  control,
  watchAllFields,
  isValid,
  trigger,
  handleAddNewHouse,
  handleHouseChange,
  handleRemoveHouse,
  isDateValid,
  openHouses,
}) {
  const checkHouseValidation = () => {
    // if (openHouses?.openHouses?.length > 0) {
    let validationError = 0;
    let dateValidationError = 0;
    openHouses?.openHouses.forEach((openHouse) => {
      if (
        openHouse?.date === undefined ||
        openHouse?.date === null ||
        openHouse?.date === "" ||
        openHouse?.start_time === undefined ||
        openHouse?.start_time === null ||
        openHouse?.start_time === "" ||
        openHouse?.end_time === undefined ||
        openHouse?.end_time === null ||
        openHouse?.end_time === ""
      ) {
        validationError = 1;
        return;
      } else {
        validationError = 0;
      }

      if (
        openHouse?.date !== undefined &&
        openHouse?.date !== null &&
        openHouse?.date !== "" &&
        isDateValid(openHouse?.date)
      ) {
        dateValidationError = 1; // or return { ...open_house } to keep the previous date
      } else {
        dateValidationError = 0;
      }
    });
    if (validationError) {
      toast.error("Please fill all fields!");
    } else if (dateValidationError) {
      toast.error("Please write future dates for open houses!");
      return;
    } else {
      changeStep(step + 1);
    }

    // }
    // else {
    //     toast.error("Please add at-least one open house here to continue!"); return;
    // }
  };
  return (
    <>
      <fieldset>
        <ToastContainer />
        <h4 className="ofer_heading">Open House Dates:</h4>
        {openHouses?.openHouses?.length > 0
          ? openHouses?.openHouses?.map((openHouse, index) => {
              return (
                <div className="form_row row add_tenants">
                  <div
                    className="closeBtn"
                    onClick={(e) => handleRemoveHouse(index, e)}
                  ></div>
                  <div className="col-xs-4">
                    <div className="form_blk">
                      {/* <InputMask
                      mask="99-99-9999"
                      name="date"
                      id="date"
                      className="txtBox"
                      autoComplete="none"
                      onChange={(e) => {
                        handleHouseChange(index, e.target.value, "date");
                      }}
                      defaultValue={
                        openHouse?.date !== "" &&
                          openHouse?.date !== null &&
                          openHouse?.date !== undefined
                          ? openHouse?.date
                          : null
                      }
                    /> */}
                      <DatePicker
                        className="txtBox"
                        placeholderText="_ _ / _ _ / _ _ _ _"
                        onChange={(date) => {
                          handleHouseChange(index, date, "date");
                        }}
                        selected={
                          openHouse?.date !== "" &&
                          openHouse?.date !== null &&
                          openHouse?.date !== undefined
                            ? new Date(openHouse?.date)
                            : null
                        }
                        minDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="form_blk">
                      <DigitalTimePicker
                        onChange={(e) =>
                          handleHouseChange(index, e, "start_time")
                        }
                        value={openHouse?.start_time}
                        placeholder="Select Start Time"
                      />
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="form_blk">
                      <DigitalTimePicker
                        onChange={(e) =>
                          handleHouseChange(index, e, "end_time")
                        }
                        value={openHouse?.end_time}
                        placeholder="Select End Time"
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
        <div
          className="add_more_tenats auto_plus_mrgn"
          onClick={handleAddNewHouse}
        >
          <i className="fa fa-plus"></i>
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={checkHouseValidation}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
