export const FETCH_SITE_SETTINGS = "FETCH_SITE_SETTINGS";
export const FETCH_SITE_SETTINGS_SUCCESS = "FETCH_SITE_SETTINGS_SUCCESS";
export const FETCH_SITE_SETTINGS_FAILED = "FETCH_SITE_SETTINGS_FAILED";

export const FETCH_VERIFY_SETTINGS = "FETCH_VERIFY_SETTINGS";
export const FETCH_VERIFY_SETTINGS_SUCCESS = "FETCH_VERIFY_SETTINGS_SUCCESS";
export const FETCH_VERIFY_SETTINGS_FAILED = "FETCH_VERIFY_SETTINGS_FAILED";

export const FETCH_HOME_CONTENT = "FETCH_HOME_CONTENT";
export const FETCH_HOME_CONTENT_SUCCESS = "FETCH_HOME_CONTENT_SUCCESS";
export const FETCH_HOME_CONTENT_FAILED = "FETCH_HOME_CONTENT_FAILED";

export const FETCH_ABOUT_CONTENT = "FETCH_ABOUT_CONTENT";
export const FETCH_ABOUT_CONTENT_SUCCESS = "FETCH_ABOUT_CONTENT_SUCCESS";
export const FETCH_ABOUT_CONTENT_FAILED = "FETCH_ABOUT_CONTENT_FAILED";

export const FETCH_SERVICES_CONTENT = "FETCH_SERVICES_CONTENT";
export const FETCH_SERVICES_CONTENT_SUCCESS = "FETCH_SERVICES_CONTENT_SUCCESS";
export const FETCH_SERVICES_CONTENT_FAILED = "FETCH_SERVICES_CONTENT_FAILED";

export const FETCH_PARTNERS_CONTENT = "FETCH_PARTNERS_CONTENT";
export const FETCH_PARTNERS_CONTENT_SUCCESS = "FETCH_PARTNERS_CONTENT_SUCCESS";
export const FETCH_PARTNERS_CONTENT_FAILED = "FETCH_PARTNERS_CONTENT_FAILED";

export const FETCH_MISSION_CONTENT = "FETCH_MISSION_CONTENT";
export const FETCH_MISSION_CONTENT_SUCCESS = "FETCH_MISSION_CONTENT_SUCCESS";
export const FETCH_MISSION_CONTENT_FAILED = "FETCH_MISSION_CONTENT_FAILED";

export const FETCH_CONTACT_CONTENT = "FETCH_CONTACT_CONTENT";
export const FETCH_CONTACT_CONTENT_SUCCESS = "FETCH_CONTACT_CONTENT_SUCCESS";
export const FETCH_CONTACT_CONTENT_FAILED = "FETCH_CONTACT_CONTENT_FAILED";

export const FETCH_FAQ_CONTENT = "FETCH_FAQ_CONTENT";
export const FETCH_FAQ_CONTENT_SUCCESS = "FETCH_FAQ_CONTENT_SUCCESS";
export const FETCH_FAQ_CONTENT_FAILED = "FETCH_FAQ_CONTENT_FAILED";

export const FETCH_PRIVACY_CONTENT = "FETCH_PRIVACY_CONTENT";
export const FETCH_PRIVACY_CONTENT_SUCCESS = "FETCH_PRIVACY_CONTENT_SUCCESS";
export const FETCH_PRIVACY_CONTENT_FAILED = "FETCH_PRIVACY_CONTENT_FAILED";

export const FETCH_TERMS_CONTENT = "FETCH_TERMS_CONTENT";
export const FETCH_TERMS_CONTENT_SUCCESS = "FETCH_TERMS_CONTENT_SUCCESS";
export const FETCH_TERMS_CONTENT_FAILED = "FETCH_TERMS_CONTENT_FAILED";

export const FETCH_BLOG_CONTENT = "FETCH_BLOG_CONTENT";
export const FETCH_BLOG_CONTENT_SUCCESS = "FETCH_BLOG_CONTENT_SUCCESS";
export const FETCH_BLOG_CONTENT_FAILED = "FETCH_BLOG_CONTENT_FAILED";

export const FETCH_BLOG_DETAIL_CONTENT = "FETCH_BLOG_DETAIL_CONTENT";
export const FETCH_BLOG_DETAIL_CONTENT_SUCCESS =
  "FETCH_BLOG_DETAIL_CONTENT_SUCCESS";
export const FETCH_BLOG_DETAIL_CONTENT_FAILED =
  "FETCH_BLOG_DETAIL_CONTENT_FAILED";

export const FETCH_CATEGORY_BLOGS_CONTENT = "FETCH_CATEGORY_BLOGS_CONTENT";
export const FETCH_CATEGORY_BLOGS_CONTENT_SUCCESS =
  "FETCH_CATEGORY_BLOGS_CONTENT_SUCCESS";
export const FETCH_CATEGORY_BLOGS_CONTENT_FAILED =
  "FETCH_CATEGORY_BLOGS_CONTENT_FAILED";

export const FETCH_NEWSLETTER = " FETCH_NEWSLETTER";
export const NEWSLETTER_MESSAGE = " NEWSLETTER_MESSAGE";
export const NEWSLETTER_MESSAGE_SUCCESS = " NEWSLETTER_MESSAGE_SUCCESS";
export const NEWSLETTER_MESSAGE_FAILED = " NEWSLETTER_MESSAGE_FAILED";

export const CONTACT_MESSAGE = "CONTACT_MESSAGE";
export const CONTACT_MESSAGE_SUCCESS = "CONTACT_MESSAGE_SUCCESS";
export const CONTACT_MESSAGE_FAILED = "CONTACT_MESSAGE_FAILED";

export const FETCH_SIGNUP_CONTENT = "FETCH_SIGNUP_CONTENT";
export const FETCH_SIGNUP_CONTENT_SUCCESS = "FETCH_SIGNUP_CONTENT_SUCCESS";
export const FETCH_SIGNUP_CONTENT_FAILED = "FETCH_SIGNUP_CONTENT_FAILED";

export const SIGNUP_MESSAGE = "SIGNUP_MESSAGE";
export const SIGNUP_MESSAGE_SUCCESS = "SIGNUP_MESSAGE_SUCCESS";
export const SIGNUP_MESSAGE_FAILED = "SIGNUP_MESSAGE_FAILED";

export const VERIFY_MESSAGE = "VERIFY_MESSAGE";
export const VERIFY_MESSAGE_SUCCESS = "VERIFY_MESSAGE_SUCCESS";
export const VERIFY_MESSAGE_FAILED = "VERIFY_MESSAGE_FAILED";

export const FETCH_LOGIN_CONTENT = "FETCH_LOGIN_CONTENT";
export const FETCH_LOGIN_CONTENT_SUCCESS = "FETCH_LOGIN_CONTENT_SUCCESS";
export const FETCH_LOGIN_CONTENT_FAILED = "FETCH_LOGIN_CONTENT_FAILED";

export const LOGIN_MESSAGE = "LOGIN_MESSAGE";
export const LOGIN_MESSAGE_SUCCESS = "LOGIN_MESSAGE_SUCCESS";
export const LOGIN_MESSAGE_FAILED = "LOGIN_MESSAGE_FAILED";

export const FETCH_FORGOT_CONTENT = "FETCH_FORGOT_CONTENT";
export const FETCH_FORGOT_CONTENT_SUCCESS = "FETCH_FORGOT_CONTENT_SUCCESS";
export const FETCH_FORGOT_CONTENT_FAILED = "FETCH_FORGOT_CONTENT_FAILED";

export const FORGOT_MESSAGE = "FORGOT_MESSAGE";
export const FORGOT_MESSAGE_SUCCESS = "FORGOT_MESSAGE_SUCCESS";
export const FORGOT_MESSAGE_FAILED = "FORGOT_MESSAGE_FAILED";

export const FETCH_RESET_CONTENT = "FETCH_RESET_CONTENT";
export const FETCH_RESET_CONTENT_SUCCESS = "FETCH_RESET_CONTENT_SUCCESS";
export const FETCH_RESET_CONTENT_FAILED = "FETCH_RESET_CONTENT_FAILED";

export const RESET_MESSAGE = "RESET_MESSAGE";
export const RESET_MESSAGE_SUCCESS = "RESET_MESSAGE_SUCCESS";
export const RESET_MESSAGE_FAILED = "RESET_MESSAGE_FAILED";

export const PROFILE_IMAGE_SETTINGS = "PROFILE_IMAGE_SETTINGS";
export const PROFILE_IMAGE_SETTINGS_SUCCESS = "PROFILE_IMAGE_SETTINGS_SUCCESS";
export const PROFILE_IMAGE_SETTINGS_FAILED = "PROFILE_IMAGE_SETTINGS_FAILED";

export const PROFILE_SETTINGS = "PROFILE_SETTINGS";
export const PROFILE_SETTINGS_SUCCESS = "PROFILE_SETTINGS_SUCCESS";
export const PROFILE_SETTINGS_FAILED = "PROFILE_SETTINGS_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const MEMBER_QUESTION = "MEMBER_QUESTION";
export const MEMBER_QUESTION_SUCCESS = "MEMBER_QUESTION_SUCCESS";
export const MEMBER_QUESTION_FAILED = "MEMBER_QUESTION_FAILED";

export const FETCH_BRANCH_CONTENT = "FETCH_BRANCH_CONTENT";
export const FETCH_BRANCH_CONTENT_SUCCESS = "FETCH_BRANCH_CONTENT_SUCCESS";
export const FETCH_BRANCH_CONTENT_FAILED = "FETCH_BRANCH_CONTENT_FAILED";

export const BRANCH_MESSAGE = "BRANCH_MESSAGE";
export const BRANCH_MESSAGE_SUCCESS = "BRANCH_MESSAGE_SUCCESS";
export const BRANCH_MESSAGE_FAILED = "BRANCH_MESSAGE_FAILED";

export const FETCH_BRANCHES_CONTENT = "FETCH_BRANCHES_CONTENT";
export const FETCH_BRANCHES_CONTENT_SUCCESS = "FETCH_BRANCHES_CONTENT_SUCCESS";
export const FETCH_BRANCHES_CONTENT_FAILED = "FETCH_BRANCHES_CONTENT_FAILED";

export const BRANCH_DELETE__MESSAGE = "BRANCH_DELETE__MESSAGE";
export const BRANCH_DELETE_MESSAGE_SUCCESS = "BRANCH_DELETE_MESSAGE_SUCCESS";
export const BRANCH_DELETE__MESSAGE_FAILED = "BRANCH_DELETE__MESSAGE_FAILED";

export const FETCH_SINGLE_FLOOR_PLAN_CONTENT =
  "FETCH_SINGLE_FLOOR_PLAN_CONTENT";
export const FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS =
  "FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS";
export const FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED =
  "FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED";

export const SINGLE_FLOOR_PLAN_MESSAGE = "SINGLE_FLOOR_PLAN_MESSAGE";
export const SINGLE_FLOOR_PLAN_MESSAGE_SUCCESS =
  "SINGLE_FLOOR_PLAN_MESSAGE_SUCCESS";
export const SINGLE_FLOOR_PLAN_MESSAGE_FAILED =
  "SINGLE_FLOOR_PLAN_MESSAGE_FAILED";

export const UPLOAD_FLOOR_MESSAGE = "UPLOAD_FLOOR_MESSAGE";
export const UPLOAD_FLOOR_MESSAGE_SUCCESS = "UPLOAD_FLOOR_MESSAGE_SUCCESS";
export const UPLOAD_FLOOR_MESSAGE_FAILED = "UPLOAD_FLOOR_MESSAGE_FAILED";

export const FETCH_FLOOR_PLANS_CONTENT = "FETCH_FLOOR_PLANS_CONTENT";
export const FETCH_FLOOR_PLANS_CONTENT_SUCCESS =
  "FETCH_FLOOR_PLANS_CONTENT_SUCCESS";
export const FETCH_FLOOR_PLANS_CONTENT_FAILED =
  "FETCH_FLOOR_PLANS_CONTENT_FAILED";

export const FLOOR_PLANS_DELETE__MESSAGE = "FLOOR_PLANS_DELETE__MESSAGE";
export const FLOOR_PLANS_DELETE_MESSAGE_SUCCESS =
  "FLOOR_PLANS_DELETE_MESSAGE_SUCCESS";
export const FLOOR_PLANS_DELETE__MESSAGE_FAILED =
  "FLOOR_PLANS_DELETE__MESSAGE_FAILED";

export const FETCH_SINGLE_PROPERTY_CONTENT = "FETCH_SINGLE_PROPERTY_CONTENT";
export const FETCH_SINGLE_PROPERTY_CONTENT_SUCCESS =
  "FETCH_SINGLE_PROPERTY_CONTENT_SUCCESS";
export const FETCH_SINGLE_PROPERTY_CONTENT_FAILED =
  "FETCH_SINGLE_PROPERTY_CONTENT_FAILED";

export const SINGLE_PROPERTY_MESSAGE = "SINGLE_PROPERTY_MESSAGE";
export const SINGLE_PROPERTY_MESSAGE_SUCCESS =
  "SINGLE_PROPERTY_MESSAGE_SUCCESS";
export const SINGLE_PROPERTY_MESSAGE_FAILED = "SINGLE_PROPERTY_MESSAGE_FAILED";

export const FETCH_PROPERTY_FLOOR_CONTENT = "FETCH_PROPERTY_FLOOR_CONTENT";
export const FETCH_PROPERTY_FLOOR_CONTENT_SUCCESS =
  "FETCH_PROPERTY_FLOOR_CONTENT_SUCCESS";
export const FETCH_PROPERTY_FLOOR_CONTENT_FAILED =
  "FETCH_PROPERTY_FLOOR_CONTENT_FAILED";

export const FETCH_PROPERTIES_CONTENT = "FETCH_PROPERTIES_CONTENT";
export const FETCH_PROPERTIES_CONTENT_SUCCESS =
  "FETCH_PROPERTIES_CONTENT_SUCCESS";
export const FETCH_PROPERTIES_CONTENT_FAILED =
  "FETCH_PROPERTIES_CONTENT_FAILED";

export const PROPERTIES_DELETE__MESSAGE = "PROPERTIES_DELETE__MESSAGE";
export const PROPERTIES_DELETE_MESSAGE_SUCCESS =
  "PROPERTIES_DELETE_MESSAGE_SUCCESS";
export const PROPERTIES_DELETE__MESSAGE_FAILED =
  "PROPERTIES_DELETE__MESSAGE_FAILED";

export const FETCH_SINGLE_LISTING_CONTENT = "FETCH_SINGLE_LISTING_CONTENT";
export const FETCH_SINGLE_LISTING_CONTENT_SUCCESS =
  "FETCH_SINGLE_LISTING_CONTENT_SUCCESS";
export const FETCH_SINGLE_LISTING_CONTENT_FAILED =
  "FETCH_SINGLE_LISTING_CONTENT_FAILED";

export const SINGLE_LISTING_MESSAGE = "SINGLE_LISTING_MESSAGE";
export const SINGLE_LISTING_MESSAGE_SUCCESS = "SINGLE_LISTING_MESSAGE_SUCCESS";
export const SINGLE_LISTING_MESSAGE_FAILED = "SINGLE_LISTING_MESSAGE_FAILED";

export const FETCH_LISTINGS_CONTENT = "FETCH_LISTINGS_CONTENT";
export const FETCH_LISTINGS_CONTENT_SUCCESS = "FETCH_LISTINGS_CONTENT_SUCCESS";
export const FETCH_LISTINGS_CONTENT_FAILED = "FETCH_LISTINGS_CONTENT_FAILED";

export const LISTINGS_DELETE__MESSAGE = "LISTINGS_DELETE__MESSAGE";
export const LISTINGS_DELETE_MESSAGE_SUCCESS =
  "LISTINGS_DELETE_MESSAGE_SUCCESS";
export const LISTINGS_DELETE__MESSAGE_FAILED =
  "LISTINGS_DELETE__MESSAGE_FAILED";

export const FETCH_FRONTEND_PROPERTY_CONTENT =
  "FETCH_FRONTEND_PROPERTY_CONTENT";
export const FETCH_FRONTEND_PROPERTY_CONTENT_SUCCESS =
  "FETCH_FRONTEND_PROPERTY_CONTENT_SUCCESS";
export const FETCH_FRONTEND_PROPERTY_CONTENT_FAILED =
  "FETCH_FRONTEND_PROPERTY_CONTENT_FAILED";

export const FETCH_PROFILE_SETTINGS = "FETCH_PROFILE_SETTINGS";
export const FETCH_PROFILE_SETTINGS_SUCCESS = "FETCH_PROFILE_SETTINGS_SUCCESS";
export const FETCH_PROFILE_SETTINGS_FAILED = "FETCH_PROFILE_SETTINGS_FAILED";

export const FETCH_SINGLE_USER_PROPERTY_CONTENT =
  "FETCH_SINGLE_USER_PROPERTY_CONTENT";
export const FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS =
  "FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS";
export const FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED =
  "FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED";

export const SINGLE_USER_PROPERTY_MESSAGE = "SINGLE_USER_PROPERTY_MESSAGE";
export const SINGLE_USER_PROPERTY_MESSAGE_SUCCESS =
  "SINGLE_USER_PROPERTY_MESSAGE_SUCCESS";
export const SINGLE_USER_PROPERTY_MESSAGE_FAILED =
  "SINGLE_USER_PROPERTY_MESSAGE_FAILED";

export const FETCH_USER_PROPERTIES_CONTENT = "FETCH_USER_PROPERTIES_CONTENT";
export const FETCH_USER_PROPERTIES_CONTENT_SUCCESS =
  "FETCH_USER_PROPERTIES_CONTENT_SUCCESS";
export const FETCH_USER_PROPERTIES_CONTENT_FAILED =
  "FETCH_USER_PROPERTIES_CONTENT_FAILED";

export const USER_PROPERTIES_DELETE__MESSAGE =
  "USER_PROPERTIES_DELETE__MESSAGE";
export const USER_PROPERTIES_DELETE_MESSAGE_SUCCESS =
  "USER_PROPERTIES_DELETE_MESSAGE_SUCCESS";
export const USER_PROPERTIES_DELETE__MESSAGE_FAILED =
  "USER_PROPERTIES_DELETE__MESSAGE_FAILED";

export const FETCH_USER_LISTINGS_CONTENT = "FETCH_USER_LISTINGS_CONTENT";
export const FETCH_USER_LISTINGS_CONTENT_SUCCESS =
  "FETCH_USER_LISTINGS_CONTENT_SUCCESS";
export const FETCH_USER_LISTINGS_CONTENT_FAILED =
  "FETCH_USER_LISTINGS_CONTENT_FAILED";

export const USER_LISTINGS_DELETE__MESSAGE = "USER_LISTINGS_DELETE__MESSAGE";
export const USER_LISTINGS_DELETE_MESSAGE_SUCCESS =
  "USER_LISTINGS_DELETE_MESSAGE_SUCCESS";
export const USER_LISTINGS_DELETE__MESSAGE_FAILED =
  "USER_LISTINGS_DELETE__MESSAGE_FAILED";

export const FETCH_USER_SINGLE_LISTINGS_CONTENT =
  "FETCH_USER_SINGLE_LISTINGS_CONTENT";
export const FETCH_USER_SINGLE_LISTINGS_CONTENT_SUCCESS =
  "FETCH_USER_SINGLE_LISTINGS_CONTENT_SUCCESS";
export const FETCH_USER_SINGLE_LISTINGS_CONTENT_FAILED =
  "FETCH_USER_SINGLE_LISTINGS_CONTENT_FAILED";

export const USER_SINGLE_LISTINGS_DELETE__MESSAGE =
  "USER_SINGLE_LISTINGS_DELETE__MESSAGE";
export const USER_SINGLE_LISTINGS_DELETE_MESSAGE_SUCCESS =
  "USER_SINGLE_LISTINGS_DELETE_MESSAGE_SUCCESS";
export const USER_SINGLE_LISTINGS_DELETE__MESSAGE_FAILED =
  "USER_SINGLE_LISTINGS_DELETE__MESSAGE_FAILED";

export const FETCH_LISTING_DETAILS_CONTENT = "FETCH_LISTING_DETAILS_CONTENT";
export const FETCH_LISTING_DETAILS_CONTENT_SUCCESS =
  "FETCH_LISTING_DETAILS_CONTENT_SUCCESS";
export const FETCH_LISTING_DETAILS_CONTENT_FAILED =
  "FETCH_LISTING_DETAILS_CONTENT_FAILED";

export const FETCH_OFFER_PROPERTY_CONTENT = "FETCH_OFFER_PROPERTY_CONTENT";
export const FETCH_OFFER_PROPERTY_CONTENT_SUCCESS =
  "FETCH_OFFER_PROPERTY_CONTENT_SUCCESS";
export const FETCH_OFFER_PROPERTY_CONTENT_FAILED =
  "FETCH_OFFER_PROPERTY_CONTENT_FAILED";

export const SEND_OFFER_MESSAGE = "SEND_OFFER_MESSAGE";
export const SEND_OFFER_MESSAGE_SUCCESS = "SEND_OFFER_MESSAGE_SUCCESS";
export const SEND_OFFER_MESSAGE_FAILED = "SEND_OFFER_MESSAGE_FAILED";

export const SEND_OFFER_PAYPAL_MESSAGE = "SEND_OFFER_PAYPAL_MESSAGE";
export const SEND_OFFER_PAYPAL_MESSAGE_SUCCESS =
  "SEND_OFFER_PAYPAL_MESSAGE_SUCCESS";
export const SEND_OFFER_PAYPAL_MESSAGE_FAILED =
  "SEND_OFFER_PAYPAL_MESSAGE_FAILED";

export const SEND_OFFER_STRIPE_MESSAGE = "SEND_OFFER_STRIPE_MESSAGE";
export const SEND_OFFER_STRIPE_MESSAGE_SUCCESS =
  "SEND_OFFER_STRIPE_MESSAGE_SUCCESS";
export const SEND_OFFER_STRIPE_MESSAGE_FAILED =
  "SEND_OFFER_STRIPE_MESSAGE_FAILED";

export const FETCH_USER_OFFERS_CONTENT = "FETCH_USER_OFFERS_CONTENT";
export const FETCH_USER_OFFERS_CONTENT_SUCCESS =
  "FETCH_USER_OFFERS_CONTENT_SUCCESS";
export const FETCH_USER_OFFERS_CONTENT_FAILED =
  "FETCH_USER_OFFERS_CONTENT_FAILED";

export const FETCH_USER_SINGLE_OFFER_CONTENT =
  "FETCH_USER_SINGLE_OFFER_CONTENT";
export const FETCH_USER_SINGLE_OFFER_CONTENT_SUCCESS =
  "FETCH_USER_SINGLE_OFFER_CONTENT_SUCCESS";
export const FETCH_USER_SINGLE_OFFER_CONTENT_FAILED =
  "FETCH_USER_SINGLE_OFFER_CONTENT_FAILED";

export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILED = "FETCH_NOTIFICATION_FAILED";

export const GOOGLE_LOGIN_MESSAGE = "GOOGLE_LOGIN_MESSAGE";
export const GOOGLE_LOGIN_MESSAGE_SUCCESS = "GOOGLE_LOGIN_MESSAGE_SUCCESS";
export const GOOGLE_LOGIN_MESSAGE_FAILED = "GOOGLE_LOGIN_MESSAGE_FAILED";

export const FETCH_SINGLE_MEDIA_CONTENT = "FETCH_SINGLE_MEDIA_CONTENT";
export const FETCH_SINGLE_MEDIA_CONTENT_SUCCESS =
  "FETCH_SINGLE_MEDIA_CONTENT_SUCCESS";
export const FETCH_SINGLE_MEDIA_CONTENT_FAILED =
  "FETCH_SINGLE_MEDIA_CONTENT_FAILED";

export const SINGLE_MEDIA_MESSAGE = "SINGLE_MEDIA_MESSAGE";
export const SINGLE_MEDIA_MESSAGE_SUCCESS = "SINGLE_MEDIA_MESSAGE_SUCCESS";
export const SINGLE_MEDIA_MESSAGE_FAILED = "SINGLE_MEDIA_MESSAGE_FAILED";

export const FETCH_MEDIA_CONTENT = "FETCH_MEDIA_CONTENT";
export const FETCH_MEDIA_CONTENT_SUCCESS = "FETCH_MEDIA_CONTENT_SUCCESS";
export const FETCH_MEDIA_CONTENT_FAILED = "FETCH_MEDIA_CONTENT_FAILED";

export const MEDIA_DELETE__MESSAGE = "MEDIA_DELETE__MESSAGE";
export const MEDIA_DELETE_MESSAGE_SUCCESS = "MEDIA_DELETE_MESSAGE_SUCCESS";
export const MEDIA_DELETE__MESSAGE_FAILED = "MEDIA_DELETE__MESSAGE_FAILED";

export const FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT =
  "FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT";
export const FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_SUCCESS =
  "FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_SUCCESS";
export const FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_FAILED =
  "FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_FAILED";

export const USER_PROPERTIES_CANCEL__MESSAGE =
  "USER_PROPERTIES_CANCEL__MESSAGE";
export const USER_PROPERTIES_CANCEL__MESSAGE_FAILED =
  "USER_PROPERTIES_CANCEL__MESSAGE_FAILED";
export const USER_PROPERTIES_CANCEL_MESSAGE_SUCCESS =
  "USER_PROPERTIES_CANCEL_MESSAGE_SUCCESS";

export const FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT =
  "FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT";
export const FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS =
  "FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS";
export const FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED =
  "FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED";

export const FETCH_SEARCH_PROPERTIES_CONTENT =
  "FETCH_SEARCH_PROPERTIES_CONTENT";
export const FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS =
  "FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS";
export const FETCH_SEARCH_PROPERTIES_CONTENT_FAILED =
  "FETCH_SEARCH_PROPERTIES_CONTENT_FAILED";

export const USER_SINGLE_OFFERS_DELETE_MESSAGE =
  "USER_SINGLE_OFFERS_DELETE_MESSAGE";
export const USER_SINGLE_OFFERS_DELETE_MESSAGE_FAILED =
  "USER_SINGLE_OFFERS_DELETE_MESSAGE_FAILED";
export const USER_SINGLE_OFFERS_DELETE_MESSAGE_SUCCESS =
  "USER_SINGLE_OFFERS_DELETE_MESSAGE_SUCCESS";

export const USER_SINGLE_OFFERS_ACTION_MESSAGE =
  "USER_SINGLE_OFFERS_ACTION_MESSAGE";
export const USER_SINGLE_OFFERS_ACTION_MESSAGE_FAILED =
  "USER_SINGLE_OFFERS_ACTION_MESSAGE_FAILED";
export const USER_SINGLE_OFFERS_ACTION_MESSAGE_SUCCESS =
  "USER_SINGLE_OFFERS_ACTION_MESSAGE_SUCCESS";

export const FETCH_SALE_OFFER_PROPERTY_CONTENT =
  "FETCH_SALE_OFFER_PROPERTY_CONTENT";
export const FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED =
  "FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED";
export const FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS =
  "FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS";

export const USER_SINGLE_OFFERS_DECLINE_MESSAGE =
  "USER_SINGLE_OFFERS_DECLINE_MESSAGE";
export const USER_SINGLE_OFFERS_DECLINE_MESSAGE_FAILED =
  "USER_SINGLE_OFFERS_DECLINE_MESSAGE_FAILED";
export const USER_SINGLE_OFFERS_DECLINE_MESSAGE_SUCCESS =
  "USER_SINGLE_OFFERS_DECLINE_MESSAGE_SUCCESS";

export const RADIUS_PROPERTIES = "RADIUS_PROPERTIES";
export const RADIUS_PROPERTIES_SUCCESS = "RADIUS_PROPERTIES_SUCCESS";
export const RADIUS_PROPERTIES_FAILED = "RADIUS_PROPERTIES_FAILED";

export const FETCH_USER_FAVORITES_CONTENT = "FETCH_USER_FAVORITES_CONTENT";
export const FETCH_USER_FAVORITES_CONTENT_SUCCESS =
  "FETCH_USER_FAVORITES_CONTENT_SUCCESS";
export const FETCH_USER_FAVORITES_CONTENT_FAILED =
  "FETCH_USER_FAVORITES_CONTENT_FAILED";

export const USER_FAVORITES_DELETE__MESSAGE = "USER_FAVORITES_DELETE__MESSAGE";
export const USER_FAVORITES_DELETE_MESSAGE_SUCCESS =
  "USER_FAVORITES_DELETE_MESSAGE_SUCCESS";
export const USER_FAVORITES_DELETE__MESSAGE_FAILED =
  "USER_FAVORITES_DELETE__MESSAGE_FAILED";

export const SEARCH_COMMERCIAL_LISTINGS = "SEARCH_COMMERCIAL_LISTINGS";
export const SEARCH_COMMERCIAL_LISTINGS_SUCCESS =
  "SEARCH_COMMERCIAL_LISTINGS_SUCCESS";
export const SEARCH_COMMERCIAL_LISTINGS_FAILED =
  "SEARCH_COMMERCIAL_LISTINGS_FAILED";
export const CHANGE_IS_HEADER_SEARCH_VALUE = "CHANGE_IS_HEADER_SEARCH_VALUE";
export const CHANGE_MAP_BOUNDS = "CHANGE_MAP_BOUNDS";

export const SEND_CHAT = "SEND_CHAT";
export const SEND_CHAT_SUCCESS = "SEND_CHAT_SUCCESS";
export const SEND_CHAT_FAILED = "SEND_CHAT_FAILED";
export const CHANGE_IS_SEND_STATUS = "CHANGE_IS_SEND_STATUS";

export const GET_CHAT = "GET_CHAT";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_FAILED = "GET_CHAT_FAILED";

export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_FAILED = "GET_CHAT_MESSAGES_FAILED";

export const RECEIVE_CHAT_MESSAGES = "RECEIVE_CHAT_MESSAGES";
export const RECEIVE_USER_ARR = "RECEIVE_USER_ARR";
export const RECEIVE_CHAT_MESSAGES_SIDEBAR_STATUS =
  "RECEIVE_CHAT_MESSAGES_SIDEBAR_STATUS";

export const GET_LEASES = "GET_LEASES";
export const GET_LEASES_SUCCESS = "GET_LEASES_SUCCESS";
export const GET_LEASES_FAILED = "GET_LEASES_FAILED";

export const GET_PROPERTY_OFFERS = "GET_PROPERTY_OFFERS";
export const GET_PROPERTY_OFFERS_FAILED = "GET_PROPERTY_OFFERS_FAILED";
export const GET_PROPERTY_OFFERS_SUCCESS = "GET_PROPERTY_OFFERS_SUCCESS";
export const GET_PROPERTY_OFFERS_EMPTY = "GET_PROPERTY_OFFERS_EMPTY";

export const GET_PROPERTY_OFFER_DATA = "GET_PROPERTY_OFFER_DATA";
export const GET_PROPERTY_OFFER_DATA_SUCCESS =
  "GET_PROPERTY_OFFER_DATA_SUCCESS";
export const GET_PROPERTY_OFFER_DATA_FAILED = "GET_PROPERTY_OFFER_DATA_FAILED";

export const CREATE_LEASE = "CREATE_LEASE";
export const CREATE_LEASE_FAILED = "CREATE_LEASE_FAILED";
export const CREATE_LEASE_SUCCESS = "CREATE_LEASE_SUCCESS";

export const FETCH_LEASE_DETAILS = "FETCH_LEASE_DETAILS";
export const FETCH_LEASE_DETAILS_SUCCESS = "FETCH_LEASE_DETAILS_SUCCESS";
export const FETCH_LEASE_DETAILS_FAILED = "FETCH_LEASE_DETAILS_FAILED";

export const LEASE_ACTION_TENANT = "LEASE_ACTION_TENANT";
export const LEASE_ACTION_TENANT_SUCCESS = "LEASE_ACTION_TENANT_SUCCESS";
export const LEASE_ACTION_TENANT_FAILED = "LEASE_ACTION_TENANT_FAILED";

export const GET_FILTER_LEASES = "GET_FILTER_LEASES";
export const GET_FILTER_LEASES_SUCCESS = "GET_FILTER_LEASES_SUCCESS";
export const GET_FILTER_LEASES_FAILED = "GET_FILTER_LEASES_FAILED";

export const GET_BRANCH_PROPERTIES = "GET_BRANCH_PROPERTIES";
export const GET_BRANCH_PROPERTIES_SUCCESS = "GET_BRANCH_PROPERTIES_SUCCESS";
export const GET_BRANCH_PROPERTIES_FAILED = "GET_BRANCH_PROPERTIES_FAILED";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAILED = "GET_PAYMENTS_FAILED";

export const FETCH_PAYMENT_DETAILS = "FETCH_PAYMENT_DETAILS";
export const FETCH_PAYMENT_DETAILS_SUCCESS = "FETCH_PAYMENT_DETAILS_SUCCESS";
export const FETCH_PAYMENT_DETAILS_FAILED = "FETCH_PAYMENT_DETAILS_FAILED";

export const SAVE_PAYMENT = "SAVE_PAYMENT";
export const SAVE_PAYMENT_SUCCESS = "SAVE_PAYMENT_SUCCESS";
export const SAVE_PAYMENT_FAILED = "SAVE_PAYMENT_FAILED";

export const SAVE_CREDIT_CARD = "SAVE_CREDIT_CARD";
export const SAVE_CREDIT_CARD_SUCCESS = "SAVE_CREDIT_CARD_SUCCESS";
export const SAVE_CREDIT_CARD_FAILED = "SAVE_CREDIT_CARD_FAILED";

export const CARD_STATUS_CHANGE = "CARD_STATUS_CHANGE";

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILED = "GET_PAYMENT_METHODS_FAILED";

export const GET_PAYMENT_METHOD_DETAILS = "GET_PAYMENT_METHOD_DETAILS";
export const GET_PAYMENT_METHOD_DETAILS_SUCCESS =
  "GET_PAYMENT_METHOD_DETAILS_SUCCESS";
export const GET_PAYMENT_METHOD_DETAILS_FAILED =
  "GET_PAYMENT_METHOD_DETAILS_FAILED";

export const DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD";
export const DELETE_CREDIT_CARD_SUCCESS = "DELETE_CREDIT_CARD_SUCCESS";
export const DELETE_CREDIT_CARD_FAILED = "DELETE_CREDIT_CARD_FAILED";

export const TERMINATE_LEASE = "TERMINATE_LEASE";
export const TERMINATE_LEASE_SUCCESS = "TERMINATE_LEASE_SUCCESS";
export const TERMINATE_LEASE_FAILED = "TERMINATE_LEASE_FAILED";

export const TERMINATE_LEASE_CANCEL = "TERMINATE_LEASE_CANCEL";
export const TERMINATE_LEASE_CANCEL_FAILED = "TERMINATE_LEASE_CANCEL_FAILED";
export const TERMINATE_LEASE_CANCEL_SUCCESS = "TERMINATE_LEASE_CANCEL_SUCCESS";

export const WITHDRAW_LEASE = "WITHDRAW_LEASE";
export const WITHDRAW_LEASE_SUCCESS = "WITHDRAW_LEASE_SUCCESS";
export const WITHDRAW_LEASE_FAILED = "WITHDRAW_LEASE_FAILED";

export const FETCH_SINGLE_BILLING_CONTENT = "FETCH_SINGLE_BILLING_CONTENT";
export const FETCH_SINGLE_BILLING_CONTENT_SUCCESS =
  "FETCH_SINGLE_BILLING_CONTENT_SUCCESS";
export const FETCH_SINGLE_BILLING_CONTENT_FAILED =
  "FETCH_SINGLE_BILLING_CONTENT_FAILED";

export const SINGLE_BILLING_MESSAGE = "SINGLE_BILLING_MESSAGE";
export const SINGLE_BILLING_MESSAGE_SUCCESS = "SINGLE_BILLING_MESSAGE_SUCCESS";
export const SINGLE_BILLING_MESSAGE_FAILED = "SINGLE_BILLING_MESSAGE_FAILED";

export const FETCH_BILLING_CONTENT = "FETCH_BILLING_CONTENT";
export const FETCH_BILLING_CONTENT_FAILED = "FETCH_BILLING_CONTENT_FAILED";
export const FETCH_BILLING_CONTENT_SUCCESS = "FETCH_BILLING_CONTENT_SUCCESS";

export const BILLING_DELETE__MESSAGE = "BILLING_DELETE__MESSAGE";
export const BILLING_DELETE_MESSAGE_SUCCESS = "BILLING_DELETE_MESSAGE_SUCCESS";
export const BILLING_DELETE__MESSAGE_FAILED = "BILLING_DELETE__MESSAGE_FAILED";

export const GET_FILTER_BILLING = "GET_FILTER_BILLING";
export const GET_FILTER_BILLING_FAILED = "GET_FILTER_BILLING_FAILED";
export const GET_FILTER_BILLING_SUCCESS = "GET_FILTER_BILLING_SUCCESS";

export const FETCH_SINGLE_CREDIT_CONTENT = "FETCH_SINGLE_CREDIT_CONTENT";
export const FETCH_SINGLE_CREDIT_CONTENT_SUCCESS =
  "FETCH_SINGLE_CREDIT_CONTENT_SUCCESS";
export const FETCH_SINGLE_CREDIT_CONTENT_FAILED =
  "FETCH_SINGLE_CREDIT_CONTENT_FAILED";

export const SINGLE_CREDIT_MESSAGE = "SINGLE_CREDIT_MESSAGE";
export const SINGLE_CREDIT_MESSAGE_SUCCESS = "SINGLE_CREDIT_MESSAGE_SUCCESS";
export const SINGLE_CREDIT_MESSAGE_FAILED = "SINGLE_CREDIT_MESSAGE_FAILED";

export const FETCH_CREDIT_CONTENT = "FETCH_CREDIT_CONTENT";
export const FETCH_CREDIT_CONTENT_FAILED = "FETCH_CREDIT_CONTENT_FAILED";
export const FETCH_CREDIT_CONTENT_SUCCESS = "FETCH_CREDIT_CONTENT_SUCCESS";

export const CREDIT_DELETE__MESSAGE = "CREDIT_DELETE__MESSAGE";
export const CREDIT_DELETE_MESSAGE_SUCCESS = "CREDIT_DELETE_MESSAGE_SUCCESS";
export const CREDIT_DELETE__MESSAGE_FAILED = "CREDIT_DELETE__MESSAGE_FAILED";

export const GET_FILTER_CREDIT = "GET_FILTER_CREDIT";
export const GET_FILTER_CREDIT_FAILED = "GET_FILTER_CREDIT_FAILED";
export const GET_FILTER_CREDIT_SUCCESS = "GET_FILTER_CREDIT_SUCCESS";

export const FETCH_SINGLE_EXPENSE_CONTENT = "FETCH_SINGLE_EXPENSE_CONTENT";
export const FETCH_SINGLE_EXPENSE_CONTENT_SUCCESS =
  "FETCH_SINGLE_EXPENSE_CONTENT_SUCCESS";
export const FETCH_SINGLE_EXPENSE_CONTENT_FAILED =
  "FETCH_SINGLE_EXPENSE_CONTENT_FAILED";
export const SINGLE_EXPENSE_MESSAGE = "SINGLE_EXPENSE_MESSAGE";
export const SINGLE_EXPENSE_MESSAGE_SUCCESS = "SINGLE_EXPENSE_MESSAGE_SUCCESS";
export const SINGLE_EXPENSE_MESSAGE_FAILED = "SINGLE_EXPENSE_MESSAGE_FAILED";
export const FETCH_EXPENSE_CONTENT = "FETCH_EXPENSE_CONTENT";
export const FETCH_EXPENSE_CONTENT_FAILED = "FETCH_EXPENSE_CONTENT_FAILED";
export const FETCH_EXPENSE_CONTENT_SUCCESS = "FETCH_EXPENSE_CONTENT_SUCCESS";
export const EXPENSE_DELETE__MESSAGE = "EXPENSE_DELETE__MESSAGE";
export const EXPENSE_DELETE_MESSAGE_SUCCESS = "EXPENSE_DELETE_MESSAGE_SUCCESS";
export const EXPENSE_DELETE__MESSAGE_FAILED = "EXPENSE_DELETE__MESSAGE_FAILED";
export const GET_FILTER_EXPENSE = "GET_FILTER_EXPENSE  ";
export const GET_FILTER_EXPENSE_FAILED = "GET_FILTER_EXPENSE_FAILED";
export const GET_FILTER_EXPENSE_SUCCESS = "GET_FILTER_EXPENSE_SUCCESS";

export const FETCH_SINGLE_PROMOTION_CONTENT = "FETCH_SINGLE_PROMOTION_CONTENT";
export const FETCH_SINGLE_PROMOTION_CONTENT_SUCCESS =
  "FETCH_SINGLE_PROMOTION_CONTENT_SUCCESS";
export const FETCH_SINGLE_PROMOTION_CONTENT_FAILED =
  "FETCH_SINGLE_PROMOTION_CONTENT_FAILED";
export const SINGLE_PROMOTION_MESSAGE = "SINGLE_PROMOTION_MESSAGE";
export const SINGLE_PROMOTION_MESSAGE_SUCCESS =
  "SINGLE_PROMOTION_MESSAGE_SUCCESS";
export const SINGLE_PROMOTION_MESSAGE_FAILED =
  "SINGLE_PROMOTION_MESSAGE_FAILED";
export const FETCH_PROMOTION_CONTENT = "FETCH_PROMOTION_CONTENT";
export const FETCH_PROMOTION_CONTENT_FAILED = "FETCH_PROMOTION_CONTENT_FAILED";
export const FETCH_PROMOTION_CONTENT_SUCCESS =
  "FETCH_PROMOTION_CONTENT_SUCCESS";
export const PROMOTION_DELETE__MESSAGE = "PROMOTION_DELETE__MESSAGE";
export const PROMOTION_DELETE_MESSAGE_SUCCESS =
  "PROMOTION_DELETE_MESSAGE_SUCCESS";
export const PROMOTION_DELETE__MESSAGE_FAILED =
  "PROMOTION_DELETE__MESSAGE_FAILED";
export const GET_FILTER_PROMOTION = "GET_FILTER_PROMOTION  ";
export const GET_FILTER_PROMOTION_FAILED = "GET_FILTER_PROMOTION_FAILED";
export const GET_FILTER_PROMOTION_SUCCESS = "GET_FILTER_PROMOTION_SUCCESS";

export const FETCH_SINGLE_DOCUMENT_CONTENT = "FETCH_SINGLE_DOCUMENT_CONTENT";
export const FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS =
  "FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS";
export const FETCH_SINGLE_DOCUMENT_CONTENT_FAILED =
  "FETCH_SINGLE_DOCUMENT_CONTENT_FAILED";
export const SINGLE_DOCUMENT_MESSAGE = "SINGLE_DOCUMENT_MESSAGE";
export const SINGLE_DOCUMENT_MESSAGE_SUCCESS =
  "SINGLE_DOCUMENT_MESSAGE_SUCCESS";
export const SINGLE_DOCUMENT_MESSAGE_FAILED = "SINGLE_DOCUMENT_MESSAGE_FAILED";
export const FETCH_DOCUMENT_CONTENT = "FETCH_DOCUMENT_CONTENT";
export const FETCH_DOCUMENT_CONTENT_FAILED = "FETCH_DOCUMENT_CONTENT_FAILED";
export const FETCH_DOCUMENT_CONTENT_SUCCESS = "FETCH_DOCUMENT_CONTENT_SUCCESS";
export const DOCUMENT_DELETE__MESSAGE = "DOCUMENT_DELETE__MESSAGE";
export const DOCUMENT_DELETE_MESSAGE_SUCCESS =
  "DOCUMENT_DELETE_MESSAGE_SUCCESS";
export const DOCUMENT_DELETE__MESSAGE_FAILED =
  "DOCUMENT_DELETE__MESSAGE_FAILED";
export const GET_FILTER_DOCUMENT = "GET_FILTER_DOCUMENT  ";
export const GET_FILTER_DOCUMENT_FAILED = "GET_FILTER_DOCUMENT_FAILED";
export const GET_FILTER_DOCUMENT_SUCCESS = "GET_FILTER_DOCUMENT_SUCCESS";

export const FETCH_SINGLE_CONTACT_CONTENT = "FETCH_SINGLE_CONTACT_CONTENT";
export const FETCH_SINGLE_CONTACT_CONTENT_SUCCESS =
  "FETCH_SINGLE_CONTACT_CONTENT_SUCCESS";
export const FETCH_SINGLE_CONTACT_CONTENT_FAILED =
  "FETCH_SINGLE_CONTACT_CONTENT_FAILED";
export const SINGLE_CONTACT_MESSAGE = "SINGLE_CONTACT_MESSAGE";
export const SINGLE_CONTACT_MESSAGE_SUCCESS = "SINGLE_CONTACT_MESSAGE_SUCCESS";
export const SINGLE_CONTACT_MESSAGE_FAILED = "SINGLE_CONTACT_MESSAGE_FAILED";
export const FETCH_CONTACTS_CONTENT = "FETCH_CONTACTS_CONTENT";
export const FETCH_CONTACTS_CONTENT_FAILED = "FETCH_CONTACTS_CONTENT_FAILED";
export const FETCH_CONTACTS_CONTENT_SUCCESS = "FETCH_CONTACTS_CONTENT_SUCCESS";
export const CONTACT_DELETE__MESSAGE = "CONTACT_DELETE__MESSAGE";
export const CONTACT_DELETE_MESSAGE_SUCCESS = "CONTACT_DELETE_MESSAGE_SUCCESS";
export const CONTACT_DELETE__MESSAGE_FAILED = "CONTACT_DELETE__MESSAGE_FAILED";
export const GET_FILTER_CONTACT = "GET_FILTER_CONTACT  ";
export const GET_FILTER_CONTACT_FAILED = "GET_FILTER_CONTACT_FAILED";
export const GET_FILTER_CONTACT_SUCCESS = "GET_FILTER_CONTACT_SUCCESS";

export const FETCH_SINGLE_EMPLOYEE_CONTENT = "FETCH_SINGLE_EMPLOYEE_CONTENT";
export const FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS =
  "FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS";
export const FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED =
  "FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED";
export const SINGLE_EMPLOYEE_MESSAGE = "SINGLE_EMPLOYEE_MESSAGE";
export const SINGLE_EMPLOYEE_MESSAGE_SUCCESS =
  "SINGLE_EMPLOYEE_MESSAGE_SUCCESS";
export const SINGLE_EMPLOYEE_MESSAGE_FAILED = "SINGLE_EMPLOYEE_MESSAGE_FAILED";
export const FETCH_EMPLOYEES_CONTENT = "FETCH_EMPLOYEES_CONTENT";
export const FETCH_EMPLOYEES_CONTENT_FAILED = "FETCH_EMPLOYEES_CONTENT_FAILED";
export const FETCH_EMPLOYEES_CONTENT_SUCCESS =
  "FETCH_EMPLOYEES_CONTENT_SUCCESS";
export const EMPLOYEE_DELETE__MESSAGE = "EMPLOYEE_DELETE__MESSAGE";
export const EMPLOYEE_DELETE_MESSAGE_SUCCESS =
  "EMPLOYEE_DELETE_MESSAGE_SUCCESS";
export const EMPLOYEE_DELETE__MESSAGE_FAILED =
  "EMPLOYEE_DELETE__MESSAGE_FAILED";
export const GET_FILTER_EMPLOYEE = "GET_FILTER_EMPLOYEE  ";
export const GET_FILTER_EMPLOYEE_FAILED = "GET_FILTER_EMPLOYEE_FAILED";
export const GET_FILTER_EMPLOYEE_SUCCESS = "GET_FILTER_EMPLOYEE_SUCCESS";

export const FIND_EXISTING_PROMOTION = "FIND_EXISTING_PROMOTION  ";
export const FIND_EXISTING_PROMOTION_SUCCESS =
  "FIND_EXISTING_PROMOTION_SUCCESS";
export const FIND_EXISTING_PROMOTION_FAILED = "FIND_EXISTING_PROMOTION_FAILED";
export const EMPTY_STATUS_VALUE = "EMPTY_STATUS_VALUE";

export const FETCH_SINGLE_MAINTENANCE_CONTENT =
  "FETCH_SINGLE_MAINTENANCE_CONTENT";
export const FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS =
  "FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS";
export const FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED =
  "FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED";
export const SINGLE_MAINTENANCE_MESSAGE = "SINGLE_MAINTENANCE_MESSAGE";
export const SINGLE_MAINTENANCE_MESSAGE_SUCCESS =
  "SINGLE_MAINTENANCE_MESSAGE_SUCCESS";
export const SINGLE_MAINTENANCE_MESSAGE_FAILED =
  "SINGLE_MAINTENANCE_MESSAGE_FAILED";
export const FETCH_MAINTENANCE_CONTENT = "FETCH_MAINTENANCE_CONTENT";
export const FETCH_MAINTENANCE_CONTENT_FAILED =
  "FETCH_MAINTENANCE_CONTENT_FAILED";
export const FETCH_MAINTENANCE_CONTENT_SUCCESS =
  "FETCH_MAINTENANCE_CONTENT_SUCCESS";
export const MAINTENANCE_DELETE__MESSAGE = "MAINTENANCE_DELETE__MESSAGE";
export const MAINTENANCE_DELETE_MESSAGE_SUCCESS =
  "MAINTENANCE_DELETE_MESSAGE_SUCCESS";
export const MAINTENANCE_DELETE__MESSAGE_FAILED =
  "MAINTENANCE_DELETE__MESSAGE_FAILED";
export const GET_FILTER_MAINTENANCE = "GET_FILTER_MAINTENANCE";
export const GET_FILTER_MAINTENANCE_FAILED = "GET_FILTER_MAINTENANCE_FAILED";
export const GET_FILTER_MAINTENANCE_SUCCESS = "GET_FILTER_MAINTENANCE_SUCCESS";

export const FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT =
  "FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT";
export const FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS =
  "FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS";
export const FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED =
  "FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED";
export const SINGLE_MAINTENANCE_REQUESTS_MESSAGE =
  "SINGLE_MAINTENANCE_REQUESTS_MESSAGE";
export const SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS =
  "SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS";
export const SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED =
  "SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED";
export const FETCH_MAINTENANCE_REQUESTS_CONTENT =
  "FETCH_MAINTENANCE_REQUESTS_CONTENT";
export const FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED =
  "FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED";
export const FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS =
  "FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS";
export const MAINTENANCE_REQUESTS_DELETE__MESSAGE =
  "MAINTENANCE_REQUESTS_DELETE__MESSAGE";
export const MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS =
  "MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS";
export const MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED =
  "MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED";
export const GET_FILTER_MAINTENANCE_REQUESTS =
  "GET_FILTER_MAINTENANCE_REQUESTS";
export const GET_FILTER_MAINTENANCE_REQUESTS_FAILED =
  "GET_FILTER_MAINTENANCE_REQUESTS_FAILED";
export const GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS =
  "GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS";
export const SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP =
  "SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP";
export const GET_MAINTENANCE_STAFF_DETAILS = "GET_MAINTENANCE_STAFF_DETAILS";
export const GET_MAINTENANCE_STAFF_DETAILS_FAILED =
  "GET_MAINTENANCE_STAFF_DETAILS_FAILED";
export const GET_MAINTENANCE_STAFF_DETAILS_SUCCESS =
  "GET_MAINTENANCE_STAFF_DETAILS_SUCCESS";
