import React, { useState, useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useParams } from "react-router-dom";
import {
  fetchSingleUserProperty,
  postProperty,
} from "../../../states/actions/fetchSingleUserProperty";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { useForm } from "react-hook-form";
import {
  checkPropertyAddressAvailability,
  postData,
  uploadImages,
  imageValidation,
  fileValidation,
  upload_file,
  getServerImage,
  deleteFromObject,
  isCheckedFeature,
  checkPropertyTitleAvailability,
} from "../../../helpers/api";
import AddUserPropertyForm from "./sections/property-steps/addUserPropertyForm";
let addressLoading = false;
let titleLoading = false;
export default function AddUserProperty() {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();

  const dispatch = useDispatch();
  const { property_id } = useParams();
  const [formStep, setFormStep] = useState(0);
  const [imageThumbnail, setThumbnail] = useState(null);
  const [labels, setLabels] = useState({
    labels: [],
  });
  const [images, setImages] = useState({ loading: false });
  const [popup, setPopup] = useState(false);
  const showPopup = () => {
    setPopup(true);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup(false);
    setVerifyAddressFile({
      ...verifyAddressFile,
      file: null,
      file_name: null,
      loading: false,
    });
  };
  async function uploadPropertyImages(e, label) {
    setImages({ ...images, loading: true });
    let uploadedImages = await uploadImages(e);
    {
      images !== undefined &&
      images !== null &&
      images !== "" &&
      label !== undefined &&
      label !== null &&
      label !== "" &&
      images[label] !== undefined &&
      images[label] !== null
        ? setImages({
            ...images,
            [label]: images[label].concat(uploadedImages),
            loading: false,
          })
        : setImages({ ...images, [label]: uploadedImages, loading: false });
    }
  }
  useEffect(() => {}, [images]);
  useEffect(() => {}, [labels]);
  useEffect(() => {}, [formStep]);
  const handleAddLabel = () => {
    setLabels({ ...labels, labels: labels.labels.concat([{ label: "" }]) });
  };
  function handleRemoveLabel(idx, label) {
    let newImages = deleteFromObject(label?.label, images);
    setLabels({
      ...labels,
      labels: labels.labels.filter((s, sidx) => idx !== sidx),
    });
    setImages(newImages);
  }
  function handleRemoveLabelImage(idx, label) {
    setImages({
      ...images,
      [label]: images[label].filter((s, sidx) => idx !== sidx),
    });
  }
  const handleLabelChange = (idx, evt) => {
    const newLabels = labels.labels.map((label, sidx) => {
      if (idx !== sidx) return label;
      return { ...label, label: evt.target.value };
    });
    setLabels({ ...labels, labels: newLabels });
  };

  const data = useSelector((state) => state.fetchSingleUserProperty.content);
  const isLoading = useSelector(
    (state) => state.fetchSingleUserProperty.isLoading
  );
  const [addressAvailable, setAddressAvailability] = useState({
    loading: false,
    address: null,
  });
  const [titleAvailable, setTitleAvailability] = useState({
    loading: false,
    title: false,
    msg: "",
  });
  const [verifyAddressFile, setVerifyAddressFile] = useState({
    file: null,
    file_name: null,
    loading: false,
  });
  const [verifyFormLoading, setVerifyFormLoading] = useState(false);

  const isFormProcessing = useSelector(
    (state) => state.fetchSingleUserProperty.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleUserProperty(property_id));
  }, []);

  useEffect(() => {
    setImages(data.property_row?.all_images);
    setLabels({
      labels:
        data.property_row?.all_labels !== undefined
          ? data.property_row?.all_labels
          : [],
    });
    setThumbnail(data.property_row?.imageThumbnail);
    setValue("property_type", data?.property_row?.property_type);
    setValue("house_style", data?.property_row?.house_style);
    setValue("studio", data?.property_row?.studio);
    setValue("air_conditioning", data?.property_row?.air_conditioning);
    setValue("heating", data?.property_row?.heating);
    setValue("flooring", data?.property_row?.flooring);
    setValue("counter_top", data?.property_row?.counter_top);
    setValue("laundry", data?.property_row?.laundry);
    setValue("parking", data?.property_row?.parking);
    setValue("parking_spaces", data?.property_row?.parking_spaces);
    setValue("guest_parking", data?.property_row?.guest_parking);
    setValue("state", data?.property_row?.state);
    setValue("address_line1", data?.property_row?.address_line1);
    setValue(
      "address_line2",
      data?.property_row?.address_line2 !== null &&
        data?.property_row?.address_line2 !== "" &&
        data?.property_row?.address_line2 !== undefined &&
        data?.property_row?.address_line2 !== "undefined"
        ? data?.property_row?.address_line2
        : ""
    );
    setValue("city", data?.property_row?.city);
    setValue("zip_code", data?.property_row?.zip_code);
    setValue("amenities", data?.property_row?.amenities);
    setValue("features", data?.property_row?.features);
  }, [data]);

  const changeStep = (step) => {
    setFormStep(step);
    setTitleAvailability({ ...titleAvailable, msg: "" });
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  console.log(addressAvailable, "addressAvailable");
  let features_ar = data?.features;
  const onSubmit = (data) => {
    if (
      imageThumbnail !== undefined &&
      imageThumbnail !== null &&
      imageThumbnail !== "" &&
      images !== undefined &&
      images !== null &&
      images !== ""
    ) {
      if (images?.kitchen === undefined || images?.kitchen?.length <= 0) {
        toast.error(
          "Please upload at least one image for the kitchen label",
          TOAST_SETTINGS
        );
        return;
      }

      if (parseInt(watchAllFields?.full_bathrooms) > 0) {
        for (
          let full_bathroom = 1;
          full_bathroom <= parseInt(watchAllFields?.full_bathrooms);
          full_bathroom++
        ) {
          if (
            images?.["full_bathroom_" + full_bathroom] === undefined ||
            images?.["full_bathroom_" + full_bathroom]?.length <= 0
          ) {
            let f_count =
              parseInt(watchAllFields?.full_bathrooms) > 1 ? full_bathroom : "";
            toast.error(
              "Please upload at least one image for the Full Bathroom " +
                f_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }
      if (parseInt(watchAllFields?.half_bathrooms) > 0) {
        for (
          let half_bathroom = 1;
          half_bathroom <= parseInt(watchAllFields?.half_bathrooms);
          half_bathroom++
        ) {
          if (
            images?.["half_bathroom_" + half_bathroom] === undefined ||
            images?.["half_bathroom_" + half_bathroom]?.length <= 0
          ) {
            let h_count =
              parseInt(watchAllFields?.half_bathrooms) > 1 ? half_bathroom : "";
            toast.error(
              "Please upload at least one image for the Half Bathroom " +
                h_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }
      if (
        parseInt(watchAllFields?.bedrooms) > 0 &&
        watchAllFields?.studio === "no"
      ) {
        for (let bed = 1; bed <= parseInt(watchAllFields?.bedrooms); bed++) {
          if (
            images?.["bedroom_" + bed] === undefined ||
            images?.["bedroom_" + bed]?.length <= 0
          ) {
            let b_count = parseInt(watchAllFields?.bedrooms) > 1 ? bed : "";
            toast.error(
              "Please upload at least one image for the Bedroom " +
                b_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }

      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "den") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "den") === 1
      ) {
        if (images?.den === undefined || images?.den?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Den label",
            TOAST_SETTINGS
          );
          return;
        }
      }
      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "basement") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "basement") ===
          1
      ) {
        if (images?.basement === undefined || images?.basement?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Basement label",
            TOAST_SETTINGS
          );
          return;
        }
      }
      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "attic") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "attic") === 1
      ) {
        if (images?.attic === undefined || images?.attic?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Attic label",
            TOAST_SETTINGS
          );
          return;
        }
      }

      let breakCondition = false;
      if (
        labels?.labels !== undefined &&
        labels?.labels !== null &&
        labels?.labels?.length > 0
      ) {
        labels.labels.map((label, sidx) => {
          if (
            label?.label !== undefined &&
            label?.label !== null &&
            label?.label !== ""
          ) {
            if (
              images?.[label?.label] === undefined ||
              images?.[label?.label] === null ||
              images?.[label?.label]?.length <= 0
            ) {
              breakCondition = true;
              return toast.error(
                "Please upload at least one image for the " +
                  (label?.label).toUpperCase() +
                  " label",
                TOAST_SETTINGS
              );
            }
          }
        });
      }
      if (breakCondition) {
        return;
      }
      // return;
      let amenities = null;
      let features = null;
      if (
        data?.features !== undefined &&
        data?.features !== null &&
        data?.features !== "" &&
        data?.features?.length > 0
      ) {
        features = data?.features;
      }
      if (
        data?.amenities !== undefined &&
        data?.amenities !== null &&
        data?.amenities !== "" &&
        data?.amenities?.length > 0
      ) {
        amenities = data?.amenities;
      }

      const images_array = JSON.stringify(images);
      let newData = "";
      if (
        addressAvailable?.address !== "" &&
        addressAvailable?.address !== null &&
        addressAvailable?.latitude !== "" &&
        addressAvailable?.latitude !== null &&
        addressAvailable?.longitude !== "" &&
        addressAvailable?.longitude !== null &&
        addressAvailable?.place_id !== "" &&
        addressAvailable?.place_id !== null &&
        addressAvailable?.place_id !== undefined &&
        addressAvailable?.latitude !== undefined &&
        addressAvailable?.longitude !== undefined &&
        addressAvailable?.address !== undefined
      ) {
        newData = {
          ...data,
          images: images_array,
          imageThumbnail: imageThumbnail,
          features: features,
          amenities: amenities,
          formatted_address: addressAvailable?.address,
          latitude: addressAvailable?.latitude,
          longitude: addressAvailable?.longitude,
          place_id: addressAvailable?.place_id,
          google_city: addressAvailable?.city,
          google_state: addressAvailable?.state,
        };
      } else {
        newData = {
          ...data,
          images: images_array,
          imageThumbnail: imageThumbnail,
          features: features,
          amenities: amenities,
          latitude: "",
          longitude: "",
          place_id: "",
          formatted_address: "",
        };
      }

      dispatch(postProperty(newData, property_id));
    } else if (
      imageThumbnail === undefined ||
      imageThumbnail === null ||
      imageThumbnail === ""
    ) {
      toast.error("Please upload a thumbnail ", TOAST_SETTINGS);
    } else if (images === undefined || images === null || images === "") {
      toast.error("Please upload images under images labels! ", TOAST_SETTINGS);
    }
  };
  async function checkAddressAvailability(data) {
    addressLoading = true;
    setAddressAvailability({ loading: false, address: null });
    let addressAvailableApi = await checkPropertyAddressAvailability(data);
    if (addressAvailableApi?.status === 1) {
      setAddressAvailability({
        ...addressAvailable,
        address: addressAvailableApi?.address,
        city: addressAvailableApi?.city,
        state: addressAvailableApi?.state,
        latitude: addressAvailableApi?.latitude,
        longitude: addressAvailableApi?.longitude,
        place_id: addressAvailableApi?.place_id,
        status: addressAvailableApi?.status,
        msg: addressAvailableApi?.msg,
        loading: false,
      });

      addressLoading = false;
    } else {
      if (addressAvailableApi?.user_property === 1) {
        toast.error(addressAvailableApi?.msg, TOAST_SETTINGS);
      }

      setAddressAvailability({
        ...addressAvailable,
        status: addressAvailableApi?.status,
        msg: addressAvailableApi?.msg,
        property_slug: addressAvailableApi?.property_slug,
        loading: false,
      });
      addressLoading = false;
    }

    if (addressAvailableApi?.status_exist === 1) {
      showPopup(true);
    }
  }
  async function checkTitleAvailability(form_data, property_id) {
    titleLoading = true;
    setTitleAvailability({ loading: true, address: false });
    let titleAvailableApi = await checkPropertyTitleAvailability(
      form_data,
      property_id
    );

    if (titleAvailableApi?.status === 1) {
      setTitleAvailability({
        ...titleAvailable,
        title: true,
        loading: false,
        msg: "",
      });
      if (
        data?.property_row !== "" &&
        data?.property_row !== undefined &&
        data?.property_row !== null &&
        data?.property_row?.id !== ""
      ) {
        if (
          data?.property_row?.address_line1 === watchAllFields?.address_line1 ||
          data?.property_row?.city === watchAllFields?.city ||
          parseInt(data?.property_row?.state) ===
            parseInt(watchAllFields?.state) ||
          data?.property_row?.zip_code === watchAllFields?.zip_code
        ) {
          if (
            data?.property_row?.address_line2 !== "" &&
            data?.property_row?.address_line2 !== null &&
            data?.property_row?.address_line2 !== undefined &&
            data?.property_row?.address_line2 !== "undefined"
          ) {
            if (
              data?.property_row?.address_line2 !==
              watchAllFields?.address_line2
            ) {
              checkAddressAvailability(watchAllFields);
            } else {
              changeStep(formStep + 1);
            }
          } else {
            changeStep(formStep + 1);
          }
        } else {
          checkAddressAvailability(watchAllFields);
        }
      } else {
        checkAddressAvailability(watchAllFields);
      }

      titleLoading = false;
    } else {
      // toast.error(addressAvailableApi?.msg,
      //     TOAST_SETTINGS
      // );
      setTitleAvailability({
        ...titleAvailable,
        title: false,
        msg: titleAvailableApi?.msg,
        loading: false,
      });
      titleLoading = false;
    }
  }
  useEffect(() => {}, [addressAvailable]);
  async function handleUpload(e) {
    setVerifyAddressFile({ ...verifyAddressFile, loading: true });
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
      toast.error(valid.error, TOAST_SETTINGS);
    } else {
      let file = await upload_file(files);
      if (file?.status === 1) {
        document.getElementById("verify_document").value = "";
        setVerifyAddressFile({
          ...verifyAddressFile,
          file: file?.file_name,
          file_name: files?.name,
          loading: false,
        });
      } else {
        setVerifyAddressFile({ ...verifyAddressFile, loading: false });
        toast.error(file?.msg, TOAST_SETTINGS);
      }
    }
  }
  async function sendPropertyRequest(e) {
    e.preventDefault();
    if (
      addressAvailable?.property_slug !== undefined &&
      addressAvailable?.property_slug !== null &&
      addressAvailable?.property_slug !== ""
    ) {
      if (
        verifyAddressFile?.file !== null &&
        verifyAddressFile?.file_name !== null
      ) {
        setVerifyFormLoading(true);
        let form_data = {
          file: verifyAddressFile?.file,
          file_name: verifyAddressFile?.file_name,
          title: watchAllFields?.title,
        };
        postData(
          "api/send-property-verify-address-request/" +
            addressAvailable?.property_slug,
          form_data
        ).then((data) => {
          setVerifyFormLoading(false);
          if (data.status === 1) {
            // toast.success(data.msg,
            //     TOAST_SETTINGS
            // );
            setTimeout(() => {
              window.location.replace("/properties");
            }, 1000);
          } else {
            toast.error(data.msg, TOAST_SETTINGS);
          }
        });
      } else {
        toast.error("Please upload a document first!", TOAST_SETTINGS);
      }
    } else {
      toast.error("Please Check address availability first", TOAST_SETTINGS);
    }
  }
  let logo = null;
  function uploadLogo() {
    logo.click();
  }

  useDocumentTitle(
    data != undefined &&
      data != null &&
      data.property_row != undefined &&
      data.property_row !== null
      ? "Edit Property - Loftus"
      : "Add Property - Loftus"
  );

  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading) return <LoadingScreen />;
  const property_row = data?.property_row;
  const countries = data?.countries;
  const states = data?.states;
  const amenities = data?.amenities;
  const features = data?.features;

  return (
    <>
      <div index="" className="main_dashboard">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={
                data != undefined &&
                data != null &&
                data.property_row != undefined &&
                data.property_row !== null
                  ? "Edit Property: " + property_row?.title
                  : "Add Property"
              }
              parent_name="Properties"
              parent_link="/properties"
            />
            <div className="page_body">
              <div className="option_lbl">
                <ul>
                  <li className={formStep === 0 ? "active" : ""}>Location</li>
                  <li className={formStep === 1 ? "active" : ""}>
                    Description
                  </li>
                  <li className={formStep === 2 ? "active" : ""}>Features</li>
                  <li className={formStep === 3 ? "active" : ""}>Media</li>
                </ul>
              </div>

              <div className="dash_form_blk">
                <AddUserPropertyForm
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  step={formStep}
                  changeStep={changeStep}
                  countries={countries}
                  states={states}
                  property_row={property_row}
                  register={register}
                  control={control}
                  watchAllFields={watchAllFields}
                  errors={errors}
                  isValid={isValid}
                  checkAddressAvailability={checkAddressAvailability}
                  addressAvailable={addressAvailable}
                  amenities={amenities}
                  isFormProcessing={isFormProcessing}
                  images={images}
                  uploadPropertyImages={uploadPropertyImages}
                  handleAddLabel={handleAddLabel}
                  handleLabelChange={handleLabelChange}
                  labels={labels}
                  handleRemoveLabelImage={handleRemoveLabelImage}
                  imageThumbnail={imageThumbnail}
                  setThumbnail={setThumbnail}
                  path="storage/floor_plans/"
                  trigger={trigger}
                  addressLoading={addressLoading}
                  popup={popup}
                  showPopup={showPopup}
                  closePopup={closePopup}
                  handleUpload={handleUpload}
                  verifyAddressFile={verifyAddressFile}
                  sendPropertyRequest={sendPropertyRequest}
                  verifyFormLoading={verifyFormLoading}
                  features={features}
                  handleRemoveLabel={handleRemoveLabel}
                  getValues={getValues}
                  setValue={setValue}
                  checkTitleAvailability={checkTitleAvailability}
                  titleAvailable={titleAvailable}
                  titleLoading={titleLoading}
                />
              </div>
            </div>
          </div>
        </section>
        {popup === true ? (
          <section
            className="popup"
            data-popup="address-not-match"
            style={{ display: "block" }}
          >
            <div className="tableDv">
              <div className="tableCell">
                <div className="_inner">
                  <div className="crosBtn" onClick={() => closePopup()}></div>
                  <h4>Verify Address</h4>
                  <hr />
                  <p>
                    This property is currently owned by another user in Loftus.
                    We will send the current owner of this property a
                    notification informing them that another user is claiming to
                    be the owner of this property. If the current owner verifies
                    that this property is no longer theirs, or if they do not
                    have reoccurring transactions and they have not responded
                    within 7 calendar days, then you will be granted permission
                    to create this property on Loftus. If you would like a
                    faster response, please attach the deed of this property
                    below. One of our agents will review and provide feedback as
                    quickly as possible. We thank you for your cooperation and
                    apologize for any inconvenience this may have caused.{" "}
                  </p>
                  <form action="" method="POST" onSubmit={sendPropertyRequest}>
                    {verifyAddressFile?.loading === true ? (
                      <div className="loading_bar">
                        <img src="/images/loading-bar.gif" />
                      </div>
                    ) : (
                      ""
                    )}
                    {verifyAddressFile?.file !== null &&
                    verifyAddressFile?.loading !== true ? (
                      <div className="flex file_preview">
                        <div className="sm_icon">
                          <img src="/images/document.png" alt="" />
                        </div>
                        <div className="_cnt">
                          <h5>{verifyAddressFile?.file_name}</h5>
                          <a
                            href={getServerImage(
                              "storage/attachments/",
                              verifyAddressFile?.file
                            )}
                            target="_blank"
                          >
                            <i className="fi-eye"></i> View File
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="image_upload_blk uploadImg default_btn_prop"
                      data-upload="gallery_image"
                      onClick={uploadLogo}
                      disabled={
                        verifyAddressFile?.loading === true ? true : false
                      }
                    >
                      <div className="small_upload_icon">
                        <img src="/images/dashboard/icons/upload.png" alt="" />
                      </div>
                      <h3>
                        Upload{" "}
                        {verifyAddressFile?.loading === true ? (
                          <i className="spinner"></i>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                    <input
                      type="file"
                      name="verify_document"
                      id="verify_document"
                      className="uploadFile"
                      onChange={handleUpload}
                      ref={(file) => (logo = file)}
                    />
                    <hr />
                    <div className="bTn justify_btn_center">
                      <button
                        type="button"
                        className="webBtn"
                        onClick={() => closePopup()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="webBtn colorBtn"
                        disabled={
                          verifyAddressFile?.loading === true ||
                          verifyFormLoading === true
                            ? true
                            : false
                        }
                      >
                        Send{" "}
                        {verifyAddressFile?.loading === true ||
                        verifyFormLoading === true ? (
                          <i className="spinner"></i>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
