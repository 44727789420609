import Breadcrumb from "../dashboard/sections/breadcrumb";
import PaymentMethodForm from "./payment-methods-sections/form";


import { getPaymentMethodDetails, saveCard } from "../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from '../../common/LoadingScreen';
import { useEffect } from "react";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export default function AddNewPaymentMethod() {
    const data = useSelector((state) => state.payments.content);
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isLoading = useSelector((state) => state.payments.isLoading);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPaymentMethodDetails());
    }, []);
    useDocumentTitle("Add Payment Method - Loftus");
    if (isLoading || !siteSettings) return <LoadingScreen />;
    let stripePromise = ''
    if (siteSettings.site_stripe_type === 1 || siteSettings.site_stripe_type === '1') {
        stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
    }
    else if (siteSettings.site_stripe_type === 0 || siteSettings.site_stripe_type === '0') {
        stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
    }
    return (
        <main index className="main_dashboard">
            <section className="dashboard">
                <div className="contain">
                    <Breadcrumb page_name={'Add Payment Method'} parent_name="Payment Methods" parent_link="/payment-methods" />
                    <div className="page_body">
                        <div className="dash_form_blk small_form_payment">
                            <Elements stripe={stripePromise}>
                                <PaymentMethodForm saveCard={saveCard} member={data?.member} />
                            </Elements>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    );
}