import React, { useState, useEffect } from "react";
import { InputMask } from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { getServerImage } from "../../../../helpers/api";
import { ToastContainer } from "react-toastify";
import ChangePhone from "../changePhone";
import ChangeEmail from "../changeEmail";

export default function ProfileForm({
  handleProfile,
  handleUpload,
  isFormImageProcessing,
  isFormProcessing,
  profileImage,
  member,
  countries,
  states,
}) {
  const [phoneStep, setPhoneStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const handleOTPChange = (code) => setOTP(code);
  const [userEmail, setUserEmail] = useState(false);
  const [userPhone, setUserPhone] = useState(false);
  const handleHideEmail = () => {
    setUserEmail(false);
    document.getElementById("emailChange").value = "";
  };
  const handleShowEmail = () => setUserEmail(true);
  const [emailVerify, setEmailVerify] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const handleShowPhoneNumber = () => setUserPhone(true);
  const handleHidePhoneNumber = () => setUserPhone(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  useEffect(() => {
    setValue(
      "dob",
      member?.dob !== "" && member?.dob !== null && member?.dob !== undefined
        ? new Date(member?.dob)
        : ""
    );
  }, [member]);
  const onSubmit = (data) => {
    handleProfile(data);
  };
  let logo = null;
  function uploadLogo() {
    logo.click();
  }
  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div className="setting_blk">
            {/* <div className="settings_header">
                            <h4>Account Details</h4>
                        </div> */}
            <div className="upLoadDp">
              <div className="ico">
                <img
                  src={getServerImage(
                    "storage/members/",
                    profileImage != "" &&
                      profileImage != undefined &&
                      profileImage != null
                      ? profileImage
                      : member.mem_image
                  )}
                  alt={
                    member.mem_type === "company"
                      ? member.mem_fullname
                      : member.mem_fname + " " + member.mem_lname
                  }
                />
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="webBtn smBtn uploadImg"
                  data-upload="dp_image"
                  data-text="Change Photo"
                  onClick={uploadLogo}
                  disabled={isFormImageProcessing === true ? "disabled" : ""}
                >
                  {isFormImageProcessing === true ? (
                    <i className="spinner"></i>
                  ) : (
                    "Change Photo"
                  )}
                </button>
                <input
                  type="file"
                  name="profile_image"
                  id="profile_image"
                  className="uploadFile"
                  onChange={handleUpload}
                  accept="image/*"
                  ref={(file) => (logo = file)}
                />
              </div>
              <div className="noHats text-center">
                (Please upload your photo)
              </div>
            </div>
            {errors.profile_image && (
              <span>{errors.profile_image.message}</span>
            )}
            <hr />

            <div className="row form_row">
              <div className="col-xs-4">
                <h6>First Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    className="txtBox"
                    defaultValue={member.mem_fname}
                    {...register("fname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Middle Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="mname"
                    id="mname"
                    className="txtBox"
                    defaultValue={member.mem_mname}
                    {...register("mname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="mname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Last Name</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    className="txtBox"
                    defaultValue={member.mem_lname}
                    {...register("lname", {
                      pattern: {
                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lname"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-xs-4">
                <h6>Phone Number</h6>
                <div className="form_blk edit_field">
                  <input
                    type="text"
                    id="phone"
                    className="txtBox"
                    defaultValue={member.mem_phone}
                    readOnly
                  />
                  <span onClick={handleShowPhoneNumber}>Edit</span>
                  {/* <input type="text" name="phone" id="phone" className="txtBox" defaultValue={member.mem_phone} {...register("phone", {
                                        required: "Required", pattern: {
                                            value: /^[0-9-]+$/,
                                            message: "Phone format is not valid!"
                                        }
                                    })} /> */}
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Email Address</h6>
                <div className="form_blk edit_field">
                  <input
                    type="text"
                    id="email"
                    className="txtBox"
                    defaultValue={member.mem_email}
                    readOnly
                  />
                  <span onClick={handleShowEmail}>Edit</span>
                </div>
              </div>
              <div className="col-xs-4">
                <h6>Date of Birth</h6>
                <div className="form_blk">
                  <Controller
                    control={control}
                    name="dob"
                    // defaultValue={member?.mem_dob !== '' && member?.mem_dob !== null && member?.mem_dob !== undefined ? new Date(member?.mem_dob) : ''}
                    render={({ field }) => (
                      <DatePicker
                        className="txtBox"
                        placeholderText="_ _ / _ _ / _ _ _ _"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        maxDate={new Date()}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <hr />

            <div className="row form_row">
              <div className="col-xs-6">
                <h6>Address Line 1</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="address1"
                    id="address1"
                    className="txtBox"
                    defaultValue={member.mem_address1}
                    {...register("address1")}
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <h6>Address Line 2</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="address2"
                    id="address2"
                    className="txtBox"
                    defaultValue={member.mem_address2}
                    {...register("address2")}
                  />
                </div>
              </div>
              <div className="clearfix"></div>

              <div className="col-xs-6">
                <h6>Country</h6>
                <div className="form_blk">
                  <Controller
                    name="country"
                    control={control}
                    defaultValue={member.country}
                    render={({ field }) => (
                      <Select
                        options={countries}
                        {...field}
                        label="Select Country"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <h6>State</h6>
                <div className="form_blk">
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={
                      member.state !== undefined &&
                      member.state !== null &&
                      member.state !== ""
                        ? member.state
                        : ""
                    }
                    render={({ field }) => (
                      <Select
                        options={states}
                        {...field}
                        label="Select State"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-xs-6">
                <h6>City</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="txtBox"
                    defaultValue={member.mem_city}
                    {...register("city")}
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <h6>ZIP Code</h6>
                <div className="form_blk">
                  <input
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    className="txtBox"
                    defaultValue={member.mem_zip}
                    {...register("zip_code", {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid value",
                      },
                      minLength: {
                        value: 5,
                        message: "Zip code should be  equal to 5 digits",
                      },
                      maxLength: {
                        value: 5,
                        message: "Zip code should be  equal to 5 digits",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="zip_code"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <hr />
            <div className="row form_row">
              <div className="col-xs-12">
                <h6>Profile Bio</h6>
                <div className="form_blk">
                  <textarea
                    name="bio"
                    id="bio"
                    className="txtBox"
                    {...register("bio")}
                  >
                    {member.mem_bio}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="cmnBtn text-center">
              <button
                type="submit"
                className="webBtn colorBtn nextBtn"
                disabled={isFormProcessing === true ? "disabled" : ""}
              >
                {isFormProcessing === true ? (
                  <i className="spinner"></i>
                ) : (
                  "Update Profile"
                )}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      {userEmail === true ? (
        <ChangeEmail
          register={register}
          errors={errors}
          handleHideEmail={handleHideEmail}
          handleSubmit={handleSubmit}
          emailVerify={emailVerify}
          setUserEmail={setUserEmail}
          reset={reset}
          setEmailVerify={setEmailVerify}
        />
      ) : (
        ""
      )}
      {userPhone === true ? (
        <ChangePhone
          register={register}
          errors={errors}
          handleHidePhoneNumber={handleHidePhoneNumber}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          phoneVerify={phoneVerify}
          phoneStep={phoneStep}
          setPhoneStep={setPhoneStep}
          OTP={OTP}
          handleOTPChange={handleOTPChange}
          setPhoneVerify={setPhoneVerify}
          setUserPhone={setUserPhone}
        />
      ) : (
        ""
      )}
    </>
  );
}
