import React from "react";

export default function PaymentTabs({
  type,
  setPaymentType,
  landlord_leases_count,
  tenant_leases_count,
  setPaymentStatus,
  paymentStatus,
  handlePaymentFilterChange,
  filterData,
  mem_type,
  branches,
}) {
  console.log("mem_type", mem_type);
  return (
    <>
      <div className="flterFlexListing flex">
        {mem_type === "member" ? (
          <ul className="nav nav-tabs">
            <li
              className={
                type === "leases" || type === "landlord_leases" ? "active" : ""
              }
            >
              <a
                className="b"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={() =>
                  setPaymentType(
                    filterData?.role === "landlord_leases"
                      ? "landlord_leases"
                      : "leases"
                  )
                }
              >
                Leases{" "}
                {type === "leases"
                  ? "(" + tenant_leases_count + ")"
                  : type === "landlord_leases"
                  ? "(" + landlord_leases_count + ")"
                  : filterData?.role === "landlord_leases"
                  ? "(" + landlord_leases_count + ")"
                  : "(" + tenant_leases_count + ")"}
              </a>
            </li>

            {mem_type !== "company" ? (
              <li className={type === "services" ? "active" : ""}>
                <a
                  className="a"
                  data-toggle="tab"
                  href="javascript:void(0)"
                  onClick={() => setPaymentType("services")}
                >
                  Services
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}
        {mem_type === "company" ? (
          <ul className="nav nav-tabs">
            <li className={filterData?.status === "accepted" ? "active" : ""}>
              <a
                className="b"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={(e) =>
                  handlePaymentFilterChange(e, "status", "accepted")
                }
              >
                Active
              </a>
            </li>
            <li className={filterData?.status === "expired" ? "active" : ""}>
              <a
                className="b"
                data-toggle="tab"
                href="javascript:void(0)"
                onClick={(e) =>
                  handlePaymentFilterChange(e, "status", "expired")
                }
              >
                Expired
              </a>
            </li>
          </ul>
        ) : (
          ""
        )}
        <div className="filter_status_offer">
          {mem_type !== "company" ? (
            <>
              <div className="col_ofer_filter">
                <div className="sorted_heading">
                  <i className="fa fa-align-left"></i>
                  <span>Role :</span>
                </div>
                <div className="sorted_drop_down">
                  <select
                    name=""
                    id=""
                    className="txtBox"
                    onChange={(e) => handlePaymentFilterChange(e, "role")}
                  >
                    <>
                      <option
                        value="landlord_leases"
                        selected={
                          filterData?.role === "landlord_leases" ? true : false
                        }
                      >
                        Landlord
                      </option>
                      <option
                        value="leases"
                        selected={filterData?.role === "leases" ? true : false}
                      >
                        Tenant
                      </option>
                    </>
                  </select>
                </div>
              </div>
              {type === "leases" || type === "landlord_leases" ? (
                <div className="col_ofer_filter">
                  <div className="sorted_heading">
                    <i className="fa fa-align-left"></i>
                    <span>Status :</span>
                  </div>
                  <div className="sorted_drop_down">
                    <select
                      name=""
                      id=""
                      className="txtBox"
                      onChange={(e) => handlePaymentFilterChange(e, "status")}
                    >
                      <>
                        <option
                          value="accepted"
                          selected={
                            filterData?.status === "accepted" ? true : false
                          }
                        >
                          Active
                        </option>
                        <option
                          value="expired"
                          selected={
                            filterData?.status === "expired" ? true : false
                          }
                        >
                          Expired
                        </option>
                      </>
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {mem_type === "company" ? (
            <div className="col_ofer_filter">
              <div className="sorted_heading">
                <span>Branch :</span>
              </div>
              <div className="sorted_drop_down">
                <select
                  name="branch_id"
                  id="branch_id"
                  className="txtBox"
                  onChange={(e) => handlePaymentFilterChange(e, "branch")}
                >
                  <option value="all">All</option>
                  {branches?.map((branch, index) => {
                    return (
                      <option
                        value={branch?.value}
                        key={index}
                        selected={
                          filterData?.branch === branch?.value ? true : false
                        }
                      >
                        {branch?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
