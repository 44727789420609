import React from 'react'
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Select from "react-select";
import { toSluUrl } from '../../../../../helpers/api';
let property_type_options = [
    {
        id: 1,
        label: "Single Family",
        value: "Single Family"
    },
    {
        id: 2,
        label: "Townhouse",
        value: "Townhouse"
    },
    {
        id: 3,
        label: "Condo",
        value: "Condo"
    },
    {
        id: 4,
        label: "Apartment",
        value: "Apartment"
    },
    {
        id: 5,
        label: "Duplex",
        value: "Duplex"
    },
    {
        id: 6,
        label: "Other",
        value: "Other"
    },
];
let house_style_options = [
    {
        id: 1,
        label: "Craftsman",
        value: "Craftsman"
    },
    {
        id: 2,
        label: "Ranch",
        value: "Ranch"
    },
    {
        id: 3,
        label: "Tudor",
        value: "Tudor"
    },
    {
        id: 4,
        label: "Victorian",
        value: "Victorian"
    },
    {
        id: 5,
        label: "Colonial",
        value: "Colonial"
    },
    {
        id: 6,
        label: "Federal",
        value: "Federal"
    },
    {
        id: 7,
        label: "Italianate",
        value: "Italianate"
    },
]
export default function PropertyStep2({ step, control, changeStep, register, errors, property_row, isValid, amenities, trigger, watchAllFields }) {
    return (
        <>
            <fieldset>
                <div className="form_row row flex">
                    <div className="col-xs-4">
                        <h6>Year Built</h6>
                        <div className="form_blk">
                            <input type="text" className="txtBox" {...register("year", {

                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                required: "Required",
                                min: {
                                    value: 1500,
                                    message: "Year must be greater than 1499."
                                }


                            })} defaultValue={property_row != undefined && property_row != null && property_row !== '' ? property_row.year : ''} />
                            <ErrorMessage
                                errors={errors}
                                name="year"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                            />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <h6>Property Type</h6>
                        <div className="form_blk">
                            {/* <select name="property_type" id="property_type" className="txtBox " {...register("property_type", { required: "Required" })}>
                                <option value="Single Family">Single Family</option>
                                <option value="Townhouse">Townhouse</option>
                                <option value="Condo">Condo</option>
                                <option value="Apartment">Apartment</option>
                                <option value="Duplex">Duplex</option>
                                <option value="Other">Other</option>
                            </select> */}
                            <Controller
                                {...register("property_type", { required: "Required" })}
                                name="property_type"
                                control={control}
                                defaultValue={property_row?.property_type}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>

                                        <Select
                                            options={property_type_options}
                                            onChange={val => {
                                                onChange(val.value);
                                            }}
                                            label="Select Branch"
                                            value={property_type_options.find(c => c.value === value)}
                                        />
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="property_type"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                            />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <h6>House Style</h6>
                        <div className="form_blk">
                            {/* <select name="house_style" id="house_style" className="txtBox " {...register("house_style", { required: "Required" })}>
                                <option value="Craftsman">Craftsman</option>
                                <option value="Ranch">Ranch</option>
                                <option value="Tudor">Tudor</option>
                                <option value="Victorian">Victorian</option>
                                <option value="Colonial">Colonial</option>
                                <option value="Federal">Federal</option>
                                <option value="Italianate">Italianate</option>
                            </select> */}
                            <Controller
                                {...register("house_style", { required: "Required" })}
                                name="house_style"
                                control={control}
                                defaultValue={property_row != undefined && property_row != null && property_row !== '' ? property_row?.house_style : ''}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>
                                        <Select
                                            options={house_style_options}
                                            onChange={val => {
                                                onChange(val.value);
                                            }}
                                            label="Select Branch"
                                            value={house_style_options.find(c => c.value === value)}
                                        />
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="house_style"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                            />
                        </div>
                    </div>
                    <div className='clearfix'></div>
                    <div className="col-xs-4">
                        <h6>Nearest Metro</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="nearest_metro" id="nearest_metro" className="txtBox" {...register("nearest_metro", {

                                pattern: {
                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                    message: 'Invalid value',
                                },
                                // required: "Required",
                                min: {
                                    value: 0.1,
                                    message: "Value must be greater than 0."
                                }


                            })} defaultValue={property_row != undefined && property_row != null && property_row !== '' ? property_row.nearest_metro : ''} />
                            <span className='dollar_label'>mi</span>
                            <ErrorMessage
                                errors={errors}
                                name="nearest_metro"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                            />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <h6>Lot Size</h6>
                        <div className="form_blk deposit_form_blk">
                            <input type="text" name="lot_size" id="lot_size" className="txtBox" {...register("lot_size", {
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'Invalid value',
                                },
                                // required: "Required",
                                // min: {
                                //     value: 0,
                                //     message: "Value must be greater than 0."
                                // },


                            })} defaultValue={property_row != undefined && property_row != null && property_row !== '' && property_row?.lot_size > 0 ? property_row.lot_size : ''} />
                            <span className='dollar_label'>SF</span>
                            <ErrorMessage
                                errors={errors}
                                name="lot_size"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                            />
                        </div>
                    </div>
                    <div className='clearfix'></div>
                    <div className="col-xs-12">
                        <h6>Description</h6>
                        <div className="form_blk">
                            <textarea name='description' id='description' className='txtBox' {...register("description", { required: "Required" })}>{property_row?.description}</textarea>
                            <ErrorMessage
                                errors={errors}
                                name="description"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                </div>
                <div className="amenities_blk_pro">
                    <h5><span>Amenities</span></h5>
                    <div className="form_row row flex">
                        {
                            amenities && amenities.length > 0 ?
                                amenities?.map((item, index) => {
                                    return (
                                        <div className="col-xs-4" key={index}>
                                            <div className="form_blk">
                                                <div className="lblBtn">
                                                    <input type="checkbox" name="amenities" defaultValue={item.id} id={toSluUrl(item.title)} {...register("amenities")}
                                                        defaultChecked={property_row != undefined && property_row != null && property_row !== '' ? watchAllFields?.amenities.includes(item.id) : false}
                                                    />
                                                    <label htmlFor={toSluUrl(item.title)}>{item.title}</label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                ""
                        }

                    </div>
                </div>

                <div className="cmnBtn text-center">
                    <button type="button" className="webBtn backBtn" onClick={() => changeStep(step - 1)}>Previous</button>
                    {
                        !isValid ?
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                trigger();
                            }} >Next</button>
                            :
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>
                    }

                </div>
            </fieldset>
        </>
    )
}
