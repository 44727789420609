import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  GET_PAYMENTS,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILED,
  FETCH_PAYMENT_DETAILS,
  FETCH_PAYMENT_DETAILS_SUCCESS,
  FETCH_PAYMENT_DETAILS_FAILED,
  SAVE_PAYMENT,
  SAVE_PAYMENT_SUCCESS,
  SAVE_PAYMENT_FAILED,
  SAVE_CREDIT_CARD,
  SAVE_CREDIT_CARD_SUCCESS,
  SAVE_CREDIT_CARD_FAILED,
  CARD_STATUS_CHANGE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILED,
  GET_PAYMENT_METHOD_DETAILS,
  GET_PAYMENT_METHOD_DETAILS_SUCCESS,
  GET_PAYMENT_METHOD_DETAILS_FAILED,
  DELETE_CREDIT_CARD,
  DELETE_CREDIT_CARD_SUCCESS,
  DELETE_CREDIT_CARD_FAILED,
} from "./actionTypes";

export const getPayments = (formData) => (dispatch) => {
  dispatch({
    type: GET_PAYMENTS,
    payload: null,
  });
  http
    .post("my-payments", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: GET_PAYMENTS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PAYMENTS_FAILED,
        payload: error,
      });
    });
};
export const getPaymentMethods = (formData) => (dispatch) => {
  dispatch({
    type: GET_PAYMENT_METHODS,
    payload: null,
  });
  http
    .post("my-payment-methods", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      //
      dispatch({
        type: GET_PAYMENT_METHODS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PAYMENT_METHODS_FAILED,
        payload: error,
      });
    });
};
export const getPaymentMethodDetails = (formData) => (dispatch) => {
  dispatch({
    type: GET_PAYMENT_METHOD_DETAILS,
    payload: null,
  });
  http
    .post("my-payment-method-single", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      //
      dispatch({
        type: GET_PAYMENT_METHOD_DETAILS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PAYMENT_METHOD_DETAILS_FAILED,
        payload: error,
      });
    });
};
export const savePayment = (formData) => (dispatch) => {
  dispatch({
    type: SAVE_PAYMENT,
    payload: null,
  });
  http
    .post("save-lease-payment", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        // toast.success(
        //     <Text string={data.msg} parse={true} />,
        //     TOAST_SETTINGS
        // );
        setTimeout(() => {
          window.location.reload();
        }, 100);
        dispatch({
          type: SAVE_PAYMENT_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: SAVE_PAYMENT_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SAVE_PAYMENT_FAILED,
        payload: error,
      });
    });
};
export const saveCard =
  (formData, ach_bank = false, payment_methods = false) =>
  (dispatch) => {
    let postUrl = "save-credit-card";
    if (ach_bank) {
      postUrl = "save-ach-bank-account";
    }
    dispatch({
      type: SAVE_CREDIT_CARD,
      payload: null,
    });
    http
      .post(postUrl, helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success(
            <Text string={data.msg} parse={true} />,
            TOAST_SETTINGS
          );
          if (payment_methods) {
            setTimeout(() => {
              window.location.href = "/payment-methods";
            }, 2000);
          }

          dispatch({
            type: SAVE_CREDIT_CARD_SUCCESS,
            payload: data,
          });
        } else {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

          dispatch({
            type: SAVE_CREDIT_CARD_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_CREDIT_CARD_FAILED,
          payload: error,
        });
      });
  };
export const deletePaymentMethod =
  (formData, ach_bank = false, payment_methods = false) =>
  (dispatch) => {
    dispatch({
      type: DELETE_CREDIT_CARD,
      payload: null,
    });
    http
      .post("delete-ach-bank-account", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success(
            <Text string={data.msg} parse={true} />,
            TOAST_SETTINGS
          );
          setTimeout(() => {
            window.location.href = "/payment-methods";
          }, 2000);

          dispatch({
            type: DELETE_CREDIT_CARD_SUCCESS,
            payload: data,
          });
        } else {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

          dispatch({
            type: DELETE_CREDIT_CARD_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CREDIT_CARD_FAILED,
          payload: error,
        });
      });
  };
export const fetchPaymentDetails =
  (lease_id, is_landlord = false) =>
  (dispatch) => {
    let getUrl = "tenant-payment-details/" + lease_id;
    if (is_landlord) {
      getUrl = "landlord-payment-details/" + lease_id;
    }

    dispatch({
      type: FETCH_PAYMENT_DETAILS,
      payload: null,
    });
    http
      .get(getUrl)
      .then(({ data }) => {
        if (data?.status === 1) {
          dispatch({
            type: FETCH_PAYMENT_DETAILS_SUCCESS,
            payload: data,
          });
        } else {
          setTimeout(() => {
            window.location.replace("/payments");
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PAYMENT_DETAILS_FAILED,
          payload: error,
        });
      });
  };
export const cardSavedStatusChange = () => (dispatch) => {
  dispatch({
    type: CARD_STATUS_CHANGE,
    payload: null,
  });
};
