import React from "react";
import BillingLst from "./biilingLst";

export default function BillingBlk({ billing, setBillingPopup, handleDelete, type = 'pending' }) {
    return <>
        {billing?.length > 0 ? (
            billing?.map((billing_row, index) => {
                return (
                    <BillingLst
                        billing_row={billing_row}
                        key={index}
                        setBillingPopup={setBillingPopup}
                        handleDelete={handleDelete}
                        type={type}
                    />
                );
            })
        ) : (
            <div className="alert alert-danger">
                No item(s) added yet!
            </div>
        )}
    </>;
}
