import { useState } from "react";
import PaymentMethodBankStep from "./steps/bank";
import PaymentMEthodCreditCardStep from "./steps/credit-card";
import PAymentMEthodStep1 from "./steps/step1";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { postData } from "../../../../helpers/api";
import { ToastContainer } from "react-toastify";

export default function PaymentMethodForm({ saveCard,member }) {
    const dispatch = useDispatch();

    const stripe = useStripe();
    const elements = useElements()
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [formStep, setFormStep] = useState(0);
    const changePaymentMethod = (e, type) => {
        e.preventDefault();
        setPaymentMethod(type)
    }
    const [checkoutError, setCheckoutError] = useState();
    const [isProcessing, setProcessingTo] = useState(false);
    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    const { register, trigger, handleSubmit, setValue, control, watch, formState: { errors, isValid } } = useForm({ mode: 'all' });
    const onSubmit = async (frmData) => {
        if (paymentMethod === 'credit-card') {
            setProcessingTo(true);
            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }
            const cardElement = elements.getElement(CardElement);
            try {
                const paymentMethodReq = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    // billing_details: billingDetails
                });
                if (paymentMethodReq.error) {
                    setCheckoutError(paymentMethodReq.error.message);
                    setProcessingTo(false);
                    return;
                }
                else {
                    var form_data = new FormData();
                    for (var key in frmData) {
                        form_data.append(key, watchAllFields[key]);

                    }
                    form_data.append('payment_method', paymentMethodReq.paymentMethod.id);
                    form_data.append('card_holder_name', frmData?.card_holder_name);
                    await postData("api/create-lease-payment-intent", form_data).then((data) => {
                        ;
                        let client_secret_setup = data.arr.setup_client_secret;
                        if (data.status === 1) {
                            let card_result = stripe.confirmCardSetup(client_secret_setup, {
                                payment_method: {
                                    card: cardElement,
                                    // billing_details: billingDetails,
                                },
                            });

                            if (card_result.error) {
                                setCheckoutError(card_result.error.message);
                                setProcessingTo(false);
                                return;
                            }
                            else {
                                let paymentMethod = '';
                                let setup_intent_id = '';
                                card_result.then(response => {
                                    if (response.error) {
                                        setCheckoutError(response.error.message);
                                        setProcessingTo(false);
                                        return;
                                    }
                                    else {

                                        paymentMethod = response.setupIntent.payment_method;
                                        setup_intent_id = response.setupIntent.id;
                                        chargePayment(paymentMethodReq, setup_intent_id, paymentMethod, data.arr.customer);
                                    }

                                })
                            }
                        }
                        else {
                            toast.error(data?.msg)
                        }
                    });
                }
            } catch (err) {
                setCheckoutError(err.message);
            }
        }
        else if (paymentMethod === 'ach') {
            let newData = { ...frmData, payment_method: 'ach' };
            dispatch(saveCard(newData, true, true));
        }

    }
    const chargePayment = async (paymentMethodReq, setup_id, paymentMethodSetup, customer_id) => {

        let data = watchAllFields;

        let newData = { ...data, payment_method_id: paymentMethodReq.paymentMethod.id, customer_id: customer_id, card_number: paymentMethodReq.paymentMethod.card.last4, card_brand: paymentMethodReq.paymentMethod.card.brand, card_exp_month: paymentMethodReq.paymentMethod.card.exp_month, card_exp_year: paymentMethodReq.paymentMethod.card.exp_year, setup_id: setup_id, payment_method: 'credit-card' };
        dispatch(saveCard(newData, false, true));
        // setAddNewCreditCard(false)
        setProcessingTo(false);
    }
    const watchAllFields = watch();
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ToastContainer />
            {
                formStep === 0 ?
                    <PAymentMEthodStep1 paymentMethod={paymentMethod} changePaymentMethod={changePaymentMethod} setFormStep={setFormStep} />
                    :
                    formStep === 1 ?
                        <fieldset>
                            {
                                paymentMethod === 'ach' ?
                                    <PaymentMethodBankStep setFormStep={setFormStep} register={register} errors={errors} member={member} />
                                    :
                                    paymentMethod === 'credit-card' ?
                                        <PaymentMEthodCreditCardStep setFormStep={setFormStep} register={register} errors={errors} CardElement={CardElement} checkoutError={checkoutError} isProcessing={isProcessing} handleCardDetailsChange={handleCardDetailsChange} />
                                        :
                                        ""
                            }


                        </fieldset>
                        :
                        ""
            }

        </form>
    );
}