import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';


export default function PaymentMEthodCreditCardStep({ setFormStep, errors, register, CardElement, handleCardDetailsChange, checkoutError, isProcessing }) {
    const isAddNewCardProcessing = useSelector((state) => state.payments.isAddNewCardProcessing);


    return (
        <>
            <div className="row form_row">
                <div className="col-xs-12 info_heading_confirm">
                    <h4>Add Credit Card</h4>
                </div>
                <div className="col-xs-12">
                    <h6>Name On Card</h6>
                    <div className="form_blk">
                        <input type="text" name="" class="txtBox" {...register("card_holder_name", {
                            pattern: {
                                value: /^[a-zA-Z][a-zA-Z ]*$/,
                                message: 'Invalid value',
                            },
                            required: "Required"
                        })} />
                        <ErrorMessage
                            errors={errors}
                            name="card_holder_name"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-xs-12">
                    <h6>Card Number</h6>
                    <div class="form_blk credit_card_inner">
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '14px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                            onChange={handleCardDetailsChange}
                        />
                        {checkoutError && <div className="alert alert-danger">{checkoutError}</div>}
                    </div>
                </div>
                {/* <div className="col-xs-12">
                    <h6>Address Line 1</h6>
                    <div className="form_blk">
                        <input type="text" name="" id="" className="txtBox" />
                    </div>
                </div>
                <div className="col-xs-12">
                    <h6>Address Line 2</h6>
                    <div className="form_blk">
                        <input type="text" name="" id="" className="txtBox" />
                    </div>
                </div>
                <div className="col-xs-4">
                    <h6>City</h6>
                    <div className="form_blk">
                        <input type="text" name="" id="" className="txtBox" />
                    </div>
                </div>
                <div className="col-xs-4">
                    <h6>State</h6>
                    <div className="form_blk">
                        <select name="" id="" className="txtBox selectpicker">
                            <option value="">- Select State -</option>
                            <option value="1">Alabama</option><option value="2">Alaska</option><option value="3">Arizona</option><option value="4">Arkansas</option><option value="5">California</option><option value="6">Colorado</option><option value="7">Connecticut</option><option value="8">Delaware</option><option value="9">District of Columbia</option><option value="10">Florida</option><option value="11">Georgia</option><option value="12">Hawaii</option><option value="13">Idaho</option><option value="14">Illinois</option><option value="15">Indiana</option><option value="16">Iowa</option><option value="17">Kansas</option><option value="18">Kentucky</option><option value="19">Louisiana</option><option value="20">Maine</option><option value="21">Maryland</option><option value="22">Massachusetts</option><option value="23">Michigan</option><option value="24">Minnesota</option><option value="25">Mississippi</option><option value="26">Missouri</option><option value="27">Montana</option><option value="28">Nebraska</option><option value="29">Nevada</option><option value="30">New Hampshire</option><option value="31">New Jersey</option><option value="32">New Mexico</option><option value="33">New York</option><option value="34">North Carolina</option><option value="35">North Dakota</option><option value="36">Ohio</option><option value="37">Oklahoma</option><option value="38">Oregon</option><option value="39">Pennsylvania</option><option value="40">Rhode Island</option><option value="41">South Carolina</option><option value="42">South Dakota</option><option value="43">Tennessee</option><option value="44">Texas</option><option value="45">Utah</option><option value="46">Vermont</option><option value="47">Virginia</option><option value="48">Washington</option><option value="49">West Virginia</option><option value="50">Wisconsin</option><option value="51">Wyoming</option>
                        </select>
                    </div>
                </div>
                <div className="col-xs-4">
                    <h6>Zip</h6>
                    <div className="form_blk">
                        <input type="text" name="" id="" className="txtBox" />
                    </div>
                </div> */}
            </div>
            <div className="cmnBtn text-center">
                <button type="button" className="webBtn backBtn" onClick={() => setFormStep(0)}>Previous</button>
                <button type="submit" class="webBtn colorBtn" disabled={
                    isProcessing || isAddNewCardProcessing
                        ? "disabled"
                        : ""
                }>Submit {isProcessing || isAddNewCardProcessing ? (
                    <i className="spinner"></i>
                ) : (
                    ""
                )}</button>
            </div>
        </>
    );
}