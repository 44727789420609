import React from "react";

export default function ListingSort({ listView, ToggleView }) {
  return (
    <>
      <div className="sorted_filter flex cell_status_offer_cell_end">
        {/* <div className="sorted_heading">
                    <i className="fa fa-align-left"></i>
                    <span>Sort by :</span>
                </div> */}
        <div className="sorting-options">
          <a
            href="javascript:void(0)"
            className={
              listView === "list"
                ? "change-view-btn lde active-view-btn"
                : "change-view-btn lde"
            }
            onClick={() => ToggleView("list")}
          >
            <i className="fa fa-th-list"></i>
          </a>
          <a
            href="javascript:void(0)"
            className={
              listView === "grid"
                ? "change-view-btn active-view-btn"
                : "change-view-btn"
            }
            onClick={() => ToggleView("grid")}
          >
            <i className="fa fa-th-large"></i>
          </a>
        </div>
      </div>
    </>
  );
}
