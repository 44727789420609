import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PayPal from "../../../common/paypal";
import { toast, ToastContainer } from "react-toastify";
import {
  convertSimpleDateUsingMoment,
  hasDatePassed,
  postData,
} from "../../../../helpers/api";
import RentalListingStep1 from "../rent-listing-steps/step1";
import RentalListingStep2 from "../rent-listing-steps/step2";
import RentalListingStep3 from "../rent-listing-steps/step3";
import RentalListingStep5 from "../rent-listing-steps/step5";
import RentalListingStep4 from "../rent-listing-steps/step4";
import RentalListingStep6 from "../rent-listing-steps/step6";
import moment from "moment";

export default function RentListingForm({
  formStep,
  register,
  errors,
  changeStep,
  watchAllFields,
  isValid,
  control,
  choosePaymentMethod,
  paymentMethod,
  getPaypalObject,
  errorPaypalOrder,
  cancelPaypalOrder,
  checkoutError,
  handleCardDetailsChange,
  handleSubmit,
  setCheckoutError,
  stripePayment,
  property_slug,
  isFormProcessing,
  siteSettings,
  trigger,
  member,
  setValue,
  current_date,
  calculateListingFee,
  calculateListingProcessingFee,
  openHouses,
  setOpenHouses,
}) {
  const handleAddNewHouse = () => {
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses.concat([
        { date: "", start_time: "", end_time: "" },
      ]),
    });
  };
  function handleRemoveHouse(idx, e) {
    e.preventDefault();
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses
        .slice(0, idx)
        .concat(
          openHouses.openHouses.slice(idx + 1, openHouses.openHouses.length)
        ),
    });
  }
  const isDateValid = (dateString) => {
    // // Assuming your date format is MM-DD-YYYY
    const enteredDate = moment(dateString).format("YYYY-MM-DD");
    return hasDatePassed(enteredDate);
  };
  const handleHouseChange = (idx, evt, type = null) => {
    const newHouses = openHouses.openHouses.map((open_house, sidx) => {
      if (idx !== sidx) return open_house;
      return { ...open_house, [type]: evt };
    });
    setOpenHouses({ ...openHouses, openHouses: newHouses });
  };

  useEffect(() => {
    setValue(
      "fname",
      member?.mem_fname !== null &&
        member?.mem_fname !== 0 &&
        member?.mem_fname !== "0" &&
        member?.mem_fname !== "" &&
        member?.mem_fname !== undefined
        ? member?.mem_fname
        : ""
    );
    setValue(
      "mname",
      member?.mem_mname !== null &&
        member?.mem_mname !== 0 &&
        member?.mem_mname !== "0" &&
        member?.mem_mname !== "" &&
        member?.mem_mname !== undefined
        ? member?.mem_mname
        : ""
    );
    setValue(
      "lname",
      member?.mem_lname !== null &&
        member?.mem_lname !== 0 &&
        member?.mem_lname !== "0" &&
        member?.mem_lname !== "" &&
        member?.mem_lname !== undefined
        ? member?.mem_lname
        : ""
    );
    setValue(
      "dob",
      member?.mem_dob !== undefined &&
        member?.mem_dob !== null &&
        member?.mem_dob !== ""
        ? member?.mem_dob
        : ""
    );
    setValue(
      "address_line1",
      member?.mem_address1 !== null &&
        member?.mem_address1 !== 0 &&
        member?.mem_address1 !== "0" &&
        member?.mem_address1 !== "" &&
        member?.mem_address1 !== undefined
        ? member?.mem_address1
        : ""
    );
    setValue(
      "address_line2",
      member?.mem_address2 !== null &&
        member?.mem_address2 !== 0 &&
        member?.mem_address2 !== "0" &&
        member?.mem_address2 !== "" &&
        member?.mem_address2 !== undefined
        ? member?.mem_address2
        : ""
    );
    setValue(
      "city",
      member?.mem_city !== null &&
        member?.mem_city !== 0 &&
        member?.mem_city !== "0" &&
        member?.mem_city !== "" &&
        member?.mem_city !== undefined
        ? member?.mem_city
        : ""
    );
    setValue(
      "state",
      member?.mem_state !== null &&
        member?.mem_state !== 0 &&
        member?.mem_state !== "0" &&
        member?.mem_state !== "" &&
        member?.mem_state !== undefined
        ? member?.mem_state
        : ""
    );
    setValue(
      "state_label",
      member?.mem_state_label !== null &&
        member?.mem_state_label !== 0 &&
        member?.mem_state_label !== "0" &&
        member?.mem_state_label !== "" &&
        member?.mem_state_label !== undefined
        ? member?.mem_state_label
        : ""
    );
    setValue(
      "zip_code",
      member?.mem_zip !== null &&
        member?.mem_zip !== 0 &&
        member?.mem_zip !== "0" &&
        member?.mem_zip !== "" &&
        member?.mem_zip !== undefined
        ? member?.mem_zip
        : ""
    );
  }, []);
  const [isProcessing, setProcessingTo] = useState(false);
  const [dobError, setDobError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const chargePayment = async (
    clientSecret,
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodSetup,
      setup_future_usage: "off_session",
    });
    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      return;
    } else if ((result.status = "succeeded")) {
      let data = watchAllFields;
      const formattedData = openHouses?.openHouses.map((item) => ({
        ...item,
        date: convertSimpleDateUsingMoment(item.date), // Format the date
      }));
      let newData = {
        ...data,
        payment_intent: result.paymentIntent.id,
        payment_method_id: paymentMethodReq.paymentMethod.id,
        customer_id: customer_id,
        property: property_slug,
        amount: calculateListingFee(),
        open_houses: JSON.stringify(formattedData),
      };
      stripePayment(newData);
      setProcessingTo(false);
    }
  };

  const checkBirthDate = () => {
    let dateString = watchAllFields?.dob;
    const formattedDate = moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
    let birthDate = new Date(formattedDate);
    let today = new Date(current_date);
    let age = today.getFullYear() - birthDate.getFullYear();
    if (age > 18) {
      changeStep(formStep + 1);
      setDobError(false);
    } else if (today.getFullYear() - birthDate.getFullYear() == 18) {
      //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
      if (today.getMonth() < birthDate.getMonth()) {
        setDobError("Must be at least 18 years old.");
      } else if (today.getMonth() == birthDate.getMonth()) {
        //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
        if (today.getDate() < birthDate.getDate()) {
          setDobError("Must be at least 18 years old.");
        } else {
          changeStep(formStep + 1);
          setDobError(false);
        }
      } else {
        changeStep(formStep + 1);
        setDobError(false);
      }
    } else {
      setDobError("Must be at least 18 years old.");
    }
    return;
  };
  const onSubmit = async (data) => {
    let birthDate = new Date(watchAllFields?.dob);
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (age < 18) {
      toast.error("Must be at least 18 years old.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (today.getFullYear() - birthDate.getFullYear() == 18) {
      //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
      if (today.getMonth() < birthDate.getMonth()) {
        toast.error("Must be at least 18 years old.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (today.getMonth() == birthDate.getMonth()) {
        //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
        if (today.getDate() < birthDate.getDate()) {
          toast.error("Must be at least 18 years old.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
      }
    }
    setProcessingTo(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      } else {
        var form_data = new FormData();
        for (var key in watchAllFields) {
          form_data.append(key, watchAllFields[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("amount", calculateListingFee());
        await postData("api/create-payment-intent", form_data).then((data) => {
          let client_secret = data.arr.client_secret;
          let client_secret_setup = data.arr.setup_client_secret;
          if (data.status === 1) {
            let card_result = stripe.confirmCardSetup(client_secret_setup, {
              payment_method: {
                card: cardElement,
                // billing_details: billingDetails,
              },
            });

            if (card_result.error) {
              setCheckoutError(card_result.error.message);
              setProcessingTo(false);
              return;
            } else {
              let paymentMethod = "";
              let setup_intent_id = "";
              card_result.then((response) => {
                if (response.error) {
                  setCheckoutError(response.error.message);
                  setProcessingTo(false);
                  return;
                } else {
                  paymentMethod = response.setupIntent.payment_method;
                  setup_intent_id = response.setupIntent.id;
                  chargePayment(
                    client_secret,
                    paymentMethodReq,
                    setup_intent_id,
                    paymentMethod,
                    data.arr.customer
                  );
                }
              });
            }
          }
        });
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
    // let newData = { ...data, paymentMethod: paymentMethod, imageThumbnail: imageThumbnail };
    //
    // dispatch(postProperty(newData, property_id))
  };

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {formStep === 0 ? (
          <RentalListingStep1
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
          />
        ) : formStep === 1 ? (
          <RentalListingStep2
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
          />
        ) : formStep === 2 ? (
          <RentalListingStep3
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            siteSettings={siteSettings}
            trigger={trigger}
            member={member}
            checkBirthDate={checkBirthDate}
            dobError={dobError}
            setValue={setValue}
          />
        ) : formStep === 3 ? (
          <RentalListingStep6
            step={formStep}
            changeStep={changeStep}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            register={register}
            control={control}
            trigger={trigger}
            siteSettings={siteSettings}
            openHouses={openHouses}
            handleAddNewHouse={handleAddNewHouse}
            handleHouseChange={handleHouseChange}
            handleRemoveHouse={handleRemoveHouse}
            isDateValid={isDateValid}
          />
        ) : formStep === 4 ? (
          <RentalListingStep4
            step={formStep}
            changeStep={changeStep}
            watchAllFields={watchAllFields}
            siteSettings={siteSettings}
            trigger={trigger}
            openHouses={openHouses}
          />
        ) : formStep === 5 ? (
          <RentalListingStep5
            step={formStep}
            changeStep={changeStep}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            register={register}
            control={control}
            trigger={trigger}
            siteSettings={siteSettings}
            calculateListingProcessingFee={calculateListingProcessingFee}
          />
        ) : formStep === 6 ? (
          <fieldset>
            <div className="form_row row justify_center_flex chk">
              <div className="col-xs-9">
                <div className="creditCard">
                  <div className="flex flex-2 headCredit cardSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card1"
                          tabindex="1"
                          name="card"
                          checked={
                            paymentMethod === "credit-card" ? true : false
                          }
                          onChange={() => choosePaymentMethod("credit-card")}
                        />
                        <label htmlFor="card1">Credit Card</label>
                      </div>
                    </div>
                    <div className="inner">
                      <ul className="text-right">
                        <li>
                          <img src="/images/dashboard/card1.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card2.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card3.svg" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  {paymentMethod === "credit-card" ? (
                    <div className="flex flex-2 cardSec">
                      <div className="inner">
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "14px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                          onChange={handleCardDetailsChange}
                        />
                        {checkoutError && (
                          <div className="alert alert-danger">
                            {checkoutError}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-2 headCredit paypalSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card2"
                          tabindex="1"
                          name="card"
                          onChange={() => choosePaymentMethod("paypal")}
                          checked={paymentMethod === "paypal" ? true : false}
                        />
                        <label htmlFor="card2">Pay Pal</label>
                      </div>
                    </div>
                    <div className="inner other_pay">
                      <img src="/images/dashboard/paypal.svg" alt="" />
                    </div>
                  </div>
                  {paymentMethod === "paypal" ? (
                    <PayPal
                      price={calculateListingFee()}
                      getPaypalObject={getPaypalObject}
                      cancelPaypalOrder={cancelPaypalOrder}
                      errorPaypalOrder={errorPaypalOrder}
                      priceMonth={watchAllFields.expires_in}
                      description="Rent Listing Creation"
                    />
                  ) : (
                    ""
                  )}
                  {/* <div className="flex flex-2 headCredit paylaterSecBar">
                                                                    <div className="inner">
                                                                        <div className="lblBtn text-left">
                                                                            <input type="radio" id="card3" tabindex="1" name="card" />
                                                                            <label htmlFor="card3">Pay Later</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="inner other_pay_more">
                                                                        <img src="/images/dashboard/pay-later.svg" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="paylaterSec">
                                                                    <div className="pay_after_btns flex">
                                                                        <a href="?" className="webBtn pay_later_btn">
                                                                            <img src="/images/dashboard/pay-later.svg" alt="" />
                                                                            <span>Pay Later</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="text-center small_card_txt">
                                                                        <p><small>After clicking "Pay Later", you will be redirected to PayLater to complete your listing securely.</small></p>
                                                                    </div>
                                                                </div> */}
                </div>
              </div>
            </div>
            <div className="cmnBtn text-center">
              <button
                type="button"
                className="webBtn backBtn"
                onClick={() => changeStep(formStep - 1)}
              >
                Previous
              </button>
              <button
                type="submit"
                className="webBtn colorBtn"
                disabled={isProcessing || isFormProcessing ? "disabled" : ""}
              >
                Submit
                {isProcessing || isFormProcessing ? (
                  <i className="spinner"></i>
                ) : (
                  ""
                )}
              </button>
            </div>
          </fieldset>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
