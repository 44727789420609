import React, { useState, useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import { toast, ToastContainer } from "react-toastify";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

import {
  fetchSingleUserListing,
  postListing,
} from "../../../states/actions/fetchSingleUserListing";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import RentListingForm from "./sections/rent-listing-form";
import { consoleLog, convertToDashesDate } from "../../../helpers/api";
export default function RentListing() {
  const [openHouses, setOpenHouses] = useState({
    openHouses: [],
  });
  const dispatch = useDispatch();
  const { property_slug } = useParams();
  const { listing_id } = useParams();
  const [formStep, setFormStep] = useState(0);
  const changeStep = (step) => {
    setFormStep(step);
  };
  const [checkoutError, setCheckoutError] = useState();
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const choosePaymentMethod = (value) => {
    setPaymentMethod(value);
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();

  const calculateListingProcessingFee = () => {
    if (
      watchAllFields?.expires_in !== "" &&
      watchAllFields?.expires_in !== undefined &&
      watchAllFields?.expires_in !== null
    ) {
      let listing_fee =
        parseInt(watchAllFields?.expires_in) *
        parseFloat(siteSettings?.site_listing_fee);
      let processing_fee =
        (listing_fee * siteSettings?.site_processing_fee) / 100;
      return parseFloat(processing_fee.toFixed(2));
    } else {
      return 0;
    }
  };
  const calculateListingFee = () => {
    let listing_fee =
      parseInt(watchAllFields?.expires_in) *
      parseFloat(siteSettings?.site_listing_fee);
    return (
      parseFloat(listing_fee) + parseFloat(calculateListingProcessingFee())
    );
  };
  const stripePayment = (data) => {
    let newData = {
      ...data,
      paymentMethod: paymentMethod,
      property: property_slug,
      listing_type: "rent",
      amount: calculateListingFee(),
      available_date: convertToDashesDate(data?.available_date),
      offer_deadline:
        data?.offer_deadline !== undefined &&
        data?.offer_deadline !== null &&
        data?.offer_deadline !== ""
          ? convertToDashesDate(data?.offer_deadline)
          : null,
      dob: convertToDashesDate(data?.dob),
      open_houses: JSON.stringify(openHouses?.openHouses),
    };
    dispatch(postListing(newData));
  };
  const getPaypalObject = (order) => {
    if (order.status === "COMPLETED") {
      let data = watchAllFields;
      let newData = {
        ...data,
        paymentMethod: paymentMethod,
        order_id: order.id,
        payer_id: order.payer.payer_id,
        property: property_slug,
        listing_type: "rent",
        amount: calculateListingFee(),
        available_date: convertToDashesDate(data?.available_date),
        dob: convertToDashesDate(data?.dob),
        open_houses: JSON.stringify(openHouses?.openHouses),
        offer_deadline:
          data?.offer_deadline !== undefined &&
          data?.offer_deadline !== null &&
          data?.offer_deadline !== ""
            ? convertToDashesDate(data?.offer_deadline)
            : null,
      };
      dispatch(postListing(newData));
    } else {
      consoleLog(order);
    }
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleUserListing.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleUserListing(property_slug));
  }, []);
  const data = useSelector((state) => state.fetchSingleUserListing.content);
  const cancelPaypalOrder = (data) => {
    toast.error("You canceled the payment with PayPal.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const errorPaypalOrder = (data) => {
    toast.error("There is a technical problem. Try again later.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  useDocumentTitle("Rent Listing - Loftus");
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (!siteSettings) return <LoadingScreen />;
  let stripePromise = "";
  if (
    siteSettings.site_stripe_type === 1 ||
    siteSettings.site_stripe_type === "1"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
  } else if (
    siteSettings.site_stripe_type === 0 ||
    siteSettings.site_stripe_type === "0"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
  }

  const rent_listing_count = data?.rent_listing_count;
  let current_date = data?.current_date;
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <ToastContainer />
            <Breadcrumb
              page_name="Add Rental Listing"
              parent_name="Listings"
              parent_link="/listing"
            />
            {rent_listing_count !== undefined &&
            rent_listing_count !== null &&
            rent_listing_count > 0 ? (
              <div className="alert alert-info">
                An active rental listing for this property already exists.
              </div>
            ) : (
              <div className="page_body">
                <div className="option_lbl">
                  <ul>
                    <li className={formStep === 0 ? "active" : ""}>Rent</li>
                    <li className={formStep === 1 ? "active" : ""}>
                      Screening
                    </li>
                    <li className={formStep === 2 ? "active" : ""}>Identity</li>
                    <li className={formStep === 3 ? "active" : ""}>
                      Open House
                    </li>
                    <li className={formStep === 4 ? "active" : ""}>
                      Confirmation
                    </li>
                    <li className={formStep === 5 ? "active" : ""}>Duration</li>
                    <li className={formStep === 6 ? "active" : ""}>Payment</li>
                  </ul>
                </div>
                <div className="dash_form_blk">
                  <Elements stripe={stripePromise}>
                    <RentListingForm
                      formStep={formStep}
                      register={register}
                      errors={errors}
                      changeStep={changeStep}
                      watchAllFields={watchAllFields}
                      isValid={isValid}
                      control={control}
                      choosePaymentMethod={choosePaymentMethod}
                      paymentMethod={paymentMethod}
                      getPaypalObject={getPaypalObject}
                      errorPaypalOrder={errorPaypalOrder}
                      cancelPaypalOrder={cancelPaypalOrder}
                      checkoutError={checkoutError}
                      handleCardDetailsChange={handleCardDetailsChange}
                      handleSubmit={handleSubmit}
                      setCheckoutError={setCheckoutError}
                      stripePayment={stripePayment}
                      property_slug={property_slug}
                      isFormProcessing={isFormProcessing}
                      siteSettings={siteSettings}
                      trigger={trigger}
                      member={member}
                      setValue={setValue}
                      current_date={current_date}
                      calculateListingFee={calculateListingFee}
                      calculateListingProcessingFee={
                        calculateListingProcessingFee
                      }
                      openHouses={openHouses}
                      setOpenHouses={setOpenHouses}
                    />
                  </Elements>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
}
