import React from "react";
import PropertyBlk from "../../common/propertyBlk";

export default function FeaturedProperties({ data }) {
  console.log(data);
  return (
    <>
      <section className="listing_property">
        <div className="contain">
          <div className="sec_heading text-center">
            <h2>{data.heading}</h2>
          </div>
          <div className="flex propert_list">
            {data?.map((val, index) => {
              return (
                <PropertyBlk
                  data={val}
                  key={index}
                  branch_id={val?.branch_id}
                />
              );
            })}
          </div>
          <div className="bTn">
            {/* <NavLink to={data.link_url} className="webBtn lgBtn">{data.link_text}</NavLink> */}
          </div>
        </div>
      </section>
    </>
  );
}
