import React, { Component, useState, useEffect } from "react";
import {
  getSiteImages,
  metaTags,
  getServerImage,
  getData,
  getServerVideo,
} from "./helpers/api";
import {
  Routes,
  Route,
  BrowserRouter,
  Outlet,
  Navigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchSiteSettings } from "./states/actions/fetchSiteSettings";

import ScrollToTop from "./components/common/ScrollToTop";
import Header from "./components/includes/header";
import Footer from "./components/includes/footer";
import Home from "./components/pages/home";
import Mission from "./components/pages/mission";
import Partner from "./components/pages/partner";
import Services from "./components/pages/services";
import About from "./components/pages/about";
import Blog from "./components/pages/blog";
import BlogDetail from "./components/pages/blog-detail";
import Faq from "./components/pages/faq";
import PrivacyPolicy from "./components/pages/privacy-policy";
import TermsConditions from "./components/pages/terms-conditions";
import Contact from "./components/pages/contact";
import Login from "./components/pages/login";
import Signup from "./components/pages/signup";
import ForgotPassword from "./components/pages/forgot-password";
import Explore from "./components/pages/explore";
import Error from "./components/pages/Error";
import CategoryPosts from "./components/pages/category-blog";
import Dashboard from "./components/pages/dashboard/dashboard";
import MemberVerification from "./components/pages/dashboard/member-verification";
import DashboardProperty from "./components/pages/dashboard-company/dashboard";
import ProfileSettings from "./components/pages/dashboard/profile-settings";
import ChangePassword from "./components/pages/dashboard/change-password";
import SecurityQuestions from "./components/pages/dashboard/security-questions";
import ResetPassword from "./components/pages/reset-password";
import Properties from "./components/pages/dashboard-company/properties";
import AddProperty from "./components/pages/dashboard-company/add-property";
import Branches from "./components/pages/dashboard-company/branches";
import AddBranch from "./components/pages/dashboard-company/add-branch";
import FloorPlans from "./components/pages/dashboard-company/floorPlans";
import AddFloorPlan from "./components/pages/dashboard-company/add-floor-plan";
import Listing from "./components/pages/dashboard-company/listing";
import AddListing from "./components/pages/dashboard-company/add-listing";
import SingleProperty from "./components/pages/single-property";
import UserProperties from "./components/pages/dashboard/properties";
import AddUserProperty from "./components/pages/dashboard/addUserProperty";
import UserListings from "./components/pages/dashboard/listings";
import SaleListing from "./components/pages/dashboard/sale-listing";
import RentListing from "./components/pages/dashboard/rent-listing";
import ListingDetails from "./components/pages/dashboard/listing-details";
import SendOffer from "./components/pages/send-offer";
import EmailVerification from "./components/pages/dashboard/email-verification";
import Verification from "./components/pages/dashboard/verification";
import Offers from "./components/pages/dashboard/offers";
import OfferDetails from "./components/pages/dashboard/my-offer-details";
import ReceivedOfferDetails from "./components/pages/dashboard/received-offer-details";
import Notification from "./components/pages/dashboard/notification";
import Leases from "./components/pages/dashboard-company/leases";
import VerifiedEmailVerification from "./components/pages/dashboard/verifiedEmailVerification";
import ChangeEmailVerification from "./components/pages/dashboard/changeEmailVerification";
import Media from "./components/pages/dashboard-company/media";
import AddMedia from "./components/pages/dashboard-company/add-media";
import SingleUserProperty from "./components/pages/dashboard/single-user-property";
import CommercialListingDetails from "./components/pages/dashboard-company/commercial-listing-details";
import SingleCommercialProperty from "./components/pages/single-commercial-property";
import EditSaleOffer from "./components/pages/dashboard/edit-sale-offer";
import EditRentOffer from "./components/pages/dashboard/edit-rent-offer";
import Favorites from "./components/pages/dashboard/favorites";

import ChatMessages from "./components/pages/dashboard/chat-messages";
import CreateLease from "./components/pages/dashboard-company/create-lease";
import LeaseDetails from "./components/pages/dashboard-company/lease-details";
import MyLeaseDetails from "./components/pages/dashboard-company/my-lease-details";
import UpdateLease from "./components/pages/dashboard-company/edit-lease";
import Payments from "./components/pages/dashboard-company/payments";
import PaymentSingle from "./components/pages/dashboard-company/payment-single";
import PaymentMethods from "./components/pages/dashboard-company/payment-methods";
import AddNewPaymentMethod from "./components/pages/dashboard-company/add-new-payment-method";
import Billing from "./components/pages/dashboard-company/billing";
import AddBilling from "./components/pages/dashboard-company/add-billing";
import Credits from "./components/pages/dashboard-company/credits";
import AddCredit from "./components/pages/dashboard-company/add-credit";
import Expenses from "./components/pages/dashboard-company/expenses";
import AddExpenses from "./components/pages/dashboard-company/add-expenses";
import Promotions from "./components/pages/dashboard-company/promotions";
import AddPromotion from "./components/pages/dashboard-company/add-promotion";
import Documents from "./components/pages/dashboard-company/documents";
import AddDocument from "./components/pages/dashboard-company/add-document";
import Contacts from "./components/pages/dashboard-company/contacts";
import AddContact from "./components/pages/dashboard-company/add-contact";
import Employees from "./components/pages/dashboard-company/employees";
import AddEmployee from "./components/pages/dashboard-company/add-employee";
// import MaintenanceRequests from "./components/pages/dashboard-company/maintenance-requests";
import MaintenceTeam from "./components/pages/dashboard/maintence_team";
import AddMaintenancePerson from "./components/pages/dashboard/add-maintenance-person";
import ViewMAintenanceDetail from "./components/pages/dashboard/view-maintenance-detail";
import MaintenanceRequests from "./components/pages/dashboard/maintenance-request";
import AddMaintenanceRequest from "./components/pages/dashboard/add-maintenance-request";
import ViewMaintenanceRequest from "./components/pages/dashboard/view-maintenance-request";
import MaintenanceProfile from "./components/pages/dashboard/maintenance-profile";
import MaintenanceProfilePropertiesPage from "./components/pages/dashboard/maintenance-profile-properties";
import ViewStaffDetails from "./components/pages/dashboard/view-staff";

const authToken = localStorage.getItem("authToken");
const mem_type = localStorage.getItem("mem_type");
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteSettings());
  }, []);

  const PageLayout = () => (
    <>
      <Header logged={authToken ? true : false} />
      <Outlet />
      <Footer logged={authToken} />
    </>
  );

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<PageLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/mission" element={<Mission />} />
            <Route exact path="/partner" element={<Partner />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog-detail/:slug" element={<BlogDetail />} />
            <Route exact path="/category/:slug" element={<CategoryPosts />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/terms-conditions"
              element={<TermsConditions />}
            />
            <Route exact path="/contact" element={<Contact />} />
            <Route
              exact
              path="/property/:slug/:listing_id"
              element={<SingleProperty />}
            />
            <Route
              exact
              path="/commercial-property/:slug"
              element={<SingleCommercialProperty />}
            />
            <Route
              exact
              path="/user-property/:slug"
              element={
                authToken && authToken != null ? (
                  <SingleUserProperty />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/login"
              element={
                authToken === null || authToken === undefined ? (
                  <Login />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              exact
              path="/signup"
              element={
                authToken === null || authToken === undefined ? (
                  <Signup />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              exact
              path="/reset-password/:token"
              element={
                authToken === null || authToken === undefined ? (
                  <ResetPassword />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                authToken === null || authToken === undefined ? (
                  <ForgotPassword />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route exact path="/explore" element={<Explore />} />
            <Route
              exact
              path="/dashboard"
              element={
                authToken && authToken != null ? (
                  mem_type &&
                  mem_type === "company" &&
                  (mem_permissions === "admin" ||
                    mem_permissions === "power") ? (
                    <DashboardProperty />
                  ) : mem_type && mem_type === "member" ? (
                    <Dashboard />
                  ) : mem_permissions === "maintenance" ? (
                    <Navigate to="/service-requests" />
                  ) : (
                    ""
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/service-requests"
              element={
                authToken && authToken != null ? (
                  mem_type &&
                  mem_type === "company" &&
                  (mem_permissions === "admin" ||
                    mem_permissions === "power" ||
                    mem_permissions === "maintenance") ? (
                    <MaintenanceRequests mem_type={mem_type} />
                  ) : mem_type === "member" ? (
                    <MaintenanceRequests mem_type={mem_type} />
                  ) : (
                    <Navigate to="/login" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-service-request/:req_type"
              element={
                authToken && authToken != null ? (
                  mem_type &&
                  mem_type === "company" &&
                  (mem_permissions === "admin" ||
                    mem_permissions === "power" ||
                    mem_permissions === "maintenance") ? (
                    <AddMaintenanceRequest />
                  ) : mem_type === "member" ? (
                    <AddMaintenanceRequest />
                  ) : (
                    <Navigate to="/login" />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/view-service-request/:request_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <ViewMaintenanceRequest mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <ViewMaintenanceRequest mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-service-request/:req_type/:request_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddMaintenanceRequest mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <AddMaintenanceRequest mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/view-service-request/:request_id/:type"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" ||
                  mem_permissions === "power" ||
                  mem_permissions === "maintenance") ? (
                  <ViewMaintenanceRequest mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <ViewMaintenanceRequest mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route
              exact
              path="/chat"
              element={authToken && authToken != null ? <ChatMessages /> : <Navigate to="/login" />}
            /> */}
            <Route
              exact
              path="/messages"
              element={
                authToken && authToken != null ? (
                  <ChatMessages />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/messages/:chat_id"
              element={
                authToken && authToken != null ? (
                  <ChatMessages />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/profile-settings"
              element={
                authToken && authToken != null ? (
                  mem_type && mem_type === "company" ? (
                    ""
                  ) : (
                    <ProfileSettings />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/service-profile"
              element={
                authToken && authToken != null ? (
                  mem_type && mem_type === "company" ? (
                    ""
                  ) : (
                    <MaintenanceProfile />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/service-profile-properties"
              element={
                authToken && authToken != null ? (
                  mem_type && mem_type === "company" ? (
                    ""
                  ) : (
                    <MaintenanceProfilePropertiesPage />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                authToken && authToken != null ? (
                  mem_type && mem_type === "company" ? (
                    ""
                  ) : (
                    <ChangePassword />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/security-questions"
              element={
                authToken && authToken != null ? (
                  mem_type && mem_type === "company" ? (
                    ""
                  ) : (
                    <SecurityQuestions />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/member-verification"
              element={
                authToken && authToken != null ? (
                  <MemberVerification />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/email-verification"
              element={
                authToken && authToken != null ? (
                  <EmailVerification />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/notification"
              element={
                authToken && authToken != null ? (
                  <Notification />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/verification/:token"
              element={<Verification />}
            />
            <Route
              exact
              path="/verified-email-verification/:token"
              element={<VerifiedEmailVerification />}
            />
            <Route
              exact
              path="/change-email-confirmation/:token"
              element={<ChangeEmailVerification />}
            />

            {/* Company Pages */}
            {/* Company Properties */}
            <Route
              exact
              path="/properties"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Properties />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <UserProperties />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/service-staff"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <MaintenceTeam mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-service-person"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <AddMaintenancePerson mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-maintenance-person/:person_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <AddMaintenancePerson mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route
              exact
              path="/view-staff/:staff_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <ViewStaffDetails mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            /> */}
            <Route
              exact
              path="/view-maintenance-detail/:person_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <ViewMAintenanceDetail mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <ViewMAintenanceDetail mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/favorites"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <Favorites />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-property"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddProperty />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <AddUserProperty />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-property/:property_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddProperty />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <AddUserProperty />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/branches"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Branches />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/floor-plans"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <FloorPlans />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/promotions"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Promotions />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-promotion"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddPromotion />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-promotion/:promotion_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddPromotion />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/documents"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Documents />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-document"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddDocument />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-document/:document_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddDocument />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/expenses"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Expenses />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-expenses"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddExpenses />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-expense/:expense_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddExpenses />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/credits"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Credits />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-credit"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddCredit />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-credit/:credit_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddCredit />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/employee"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Employees />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-employee"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddEmployee />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-employee/:employee_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddEmployee />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/contacts"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Contacts />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-contact"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddContact />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-contact/:contact_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddContact />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/billing"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Billing />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-billing"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddBilling />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-billing/:billing_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddBilling />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/media"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Media />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-media"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddMedia />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-media/:media_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddMedia />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/listing"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Listing />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <UserListings />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/offers"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Offers mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <Offers mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/leases"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Leases mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <Leases mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/payment-methods"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" ? (
                  <PaymentMethods mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <PaymentMethods mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-payment-method"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" ? (
                  <AddNewPaymentMethod />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <AddNewPaymentMethod />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/payments"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Payments mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <Payments mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/payment/:payment_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <PaymentSingle />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <PaymentSingle />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/landlord_payment/:payment_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <PaymentSingle is_landlord={true} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <PaymentSingle is_landlord={true} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/lease-details/:lease_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <LeaseDetails mem_type="company" />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <LeaseDetails mem_type="member" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/my-lease-details/:lease_id"
              element={
                authToken &&
                authToken != null &&
                (authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <MyLeaseDetails mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                ))
              }
            />
            <Route
              exact
              path="/create-lease"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <CreateLease mem_type="company" />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <CreateLease mem_type="member" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-lease/:lease_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <UpdateLease mem_type="company" />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <UpdateLease mem_type="member" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/my-offer/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <OfferDetails />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <OfferDetails />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/received-offer/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <ReceivedOfferDetails mem_type={mem_type} />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <ReceivedOfferDetails mem_type={mem_type} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/sale-listing/:property_slug"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SaleListing />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route
              exact
              path="/listing-details/:slug"
              element={authToken && authToken != null && mem_type && mem_type === 'member' ? <ListingDetails /> : <Navigate to="/login" />}
            /> */}
            <Route
              exact
              path="/listing-details/:slug"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <CommercialListingDetails />
                ) : authToken &&
                  authToken != null &&
                  mem_type &&
                  mem_type === "member" ? (
                  <ListingDetails />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/rent-listing/:property_slug"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <RentListing />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/send-offer/:slug"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SendOffer property_type="user" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/send-commercial-offer/:slug/:listing_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SendOffer property_type="commercial" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/send-user-offer/:slug/:listing_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SendOffer property_type="user" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-sale-offer/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <EditSaleOffer />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-rent-offer/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <EditRentOffer />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/send-offer/:slug/:listing_id/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SendOffer property_type="user" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/send-offer-commercial/:slug/:listing_id/:offer_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "member" ? (
                  <SendOffer property_type="commercial" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-branch"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddBranch />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-listing"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddListing />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-listing/:listing_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddListing />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/add-floor-plan"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddFloorPlan />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-floor-plan/:floor_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                (mem_permissions === "admin" || mem_permissions === "power") ? (
                  <AddFloorPlan />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              exact
              path="/edit-branch/:branch_id"
              element={
                authToken &&
                authToken != null &&
                mem_type &&
                mem_type === "company" &&
                mem_permissions === "admin" ? (
                  <AddBranch />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Route>

          <Route path="*" element={<Error />} />
          <Route path="/404" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
